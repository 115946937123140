import React, {useEffect, useRef, useState} from "react";
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {Button, Input, message, Space, Spin} from "antd";
import {createPost, deletePost, updatePost} from "../../../api";
import {Util} from "../../../utils";
import {useDispatch, useSelector} from "react-redux";
import {clearEditPost} from "../../../store/thunks/common";

export const CreatePost = () => {
    const editorRef = useRef();
    let title = ''
    let postContent = ''

    const {editPost} = useSelector(state => state.common)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        title = editPost?.title
        postContent = editPost?.content;
    }, [])

    function onTitleChanged(e) {
        title = e.target.value
    }

    function onContentChanged(content) {
        postContent = content
    }

    async function submitPost() {
        if (!title) {
            await message.error("Tiêu đề không được bỏ trống")
            return
        }
        if (!Util.getTextFormHtml(postContent)) {
            await message.error("Nội dung không được bỏ trống")
            return
        }
        let data = {
            title: title,
            content: postContent
        }
        setLoading(true)
        if (!editPost) {
            createPost(data)
                .then(async (res) => {
                    dispatch(clearEditPost())
                    setLoading(false)
                    await message.success(res.data.message)
                })
                .catch(async (err) => {
                    setLoading(false)
                    await message.error(err.response.data.message)
                })
        } else {
            updatePost({
                _id: editPost._id,
                ...data
            })
                .then(async (res) => {
                    dispatch(clearEditPost())
                    setLoading(false)
                    message.success(res.data.message)
                })
                .catch(async (err) => {
                    setLoading(false)
                    await message.error(err.response.data.message)
                })
        }
    }

    function removePost() {
        if (editPost) {
            setLoading(true)
            deletePost({id: editPost._id})
                .then(async (res) => {
                    setLoading(false)
                    dispatch(clearEditPost())
                    message.success(res.data.message)
                })
                .catch(async (err) => {
                    await message.error(err.response.data.message)
                })
        }
    }

    return <Spin spinning={loading}>
        <div style={{
            margin: 8, display:
                "flex", alignItems: 'center',
            flexDirection: "column",
        }}>
            <Input placeholder="Tiêu đề" defaultValue={editPost?.title} onChange={onTitleChanged}/>
            <br/>
            <SunEditor
                showToolbar={true}
                enableToolbar={true}
                setDefaultStyle="font-family: calibri; font-size: 15px;"
                ref={editorRef}
                defaultValue={editPost?.content}
                setOptions={{
                    height: 300,
                    width: '100%',
                    buttonList: buttonList.complex
                }}
                onChange={onContentChanged}
                onImageUploadBefore={(files, info, uploadHandler) => {
                    try {
                        ResizeImage(files, uploadHandler)
                    } catch (err) {
                        uploadHandler(err.toString())
                    }
                }}
            />
            <br/>

            <Space size={100}>
                {editPost ?
                    <Button type="danger" style={{width: 150}} onClick={removePost}>Xóa
                        bài</Button> : <></>}
                <Button htmlType="submit" type="primary" style={{width: 150}}
                        onClick={submitPost}
                > {editPost ? "Cập nhật" : "Đăng"}</Button>
            </Space>

        </div>
    </Spin>
};

function ResizeImage(files, uploadHandler) {
    const uploadFile = files[0];
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    const reader = new FileReader();

    reader.onload = function (e) {
        img.src = e.target.result
        img.onload = function () {
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            const MAX_WIDTH = 500;
            const MAX_HEIGHT = 500;
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;

            ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(function (blob) {
                uploadHandler([new File([blob], uploadFile.name)])
            }, uploadFile.type, .7);
        }
    }

    reader.readAsDataURL(uploadFile);
}