import React, { useEffect, useState } from "react";
import { Button, Col, Input, message, Row, Table } from "antd";
import {
  PhoneOutlined,
  PhoneTwoTone,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ConsigneeModal from "./ConsigneeModal";
import { getConsignee } from "../../../store/actions/consignee_actions";
import CreateShippingModal from "./CreateShipping";
import { getColumnSearchProps } from "../../shared/search_menu";
import Search from "antd/lib/input/Search";
import "../../style/consignee.css";
import { MaskedInput } from "antd-mask-input";
import { getConsigneeByCustomer, getConsignees } from "../../../api";
import { map } from "ramda";
import NumericInput from "../../shared/InputNumber";

export default function Consignee() {
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      width: 80,
      align: "center",
    },
    {
      title: "Tạo đơn",
      key: "edit",
      width: "100px",
      align: "center",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={async () => {
            await setConsignee(record);
            setPopupShippingVisible(true);
          }}
        >
          Gửi
        </Button>
      ),
    },
    {
      title: "Tên",
      dataIndex: "name",
      width: 150,
      ...getColumnSearchProps("Tên", "name"),
    },
    {
      title: "SĐT",
      dataIndex: "phone",
      width: 100,
      ...getColumnSearchProps("SĐT", "phone"),
    },
    {
      title: "Địa chỉ",
      width: 200,
      dataIndex: "address",
    },
    {
      title: "Chỉnh sửa",
      key: "edit",
      width: 100,
      align: "center",
      render: (_, record) => (
        <Button
          type="link"
          onClick={async () => {
            await setConsignee(record);
            await setPopupConsigneeVisible(true);
          }}
        >
          Cập nhật
        </Button>
      ),
    },
  ];

  const state = {
    bordered: true,
    size: "small",
    showHeader: true,
    scroll: undefined,
    hasData: true,
    tableLayout: "fixed",
  };

  //popup consignee
  const [popupConsigneeVisible, setPopupConsigneeVisible] = useState(false);
  const [consignee, setConsignee] = useState(null);
  const onPopupConsigneeCreate = () => {
    setPopupConsigneeVisible(false);
    if (user.role === 1) {
      _getConsigneeByCustomer(phone);
    } else {
      _requestConsignee(null);
    }
  };

  //popup add shipping
  const [popupShippingVisible, setPopupShippingVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [consignees, setConsignees] = useState([]);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    _requestConsignee(null);
  }, [dispatch]);

  const _requestConsignee = (filter) => {
    setLoading(true);
    getConsignees(filter)
      .then((res) => {
        let mapping = res.data?.map((e, idx) => {
          return { index: idx, ...e };
        });
        setConsignees(mapping);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const roleViews = () => {
    if (user.role != 1) {
      return (
        <div>
          <Button
            type="primary"
            className="add-consignee"
            icon={<PlusOutlined />}
            style={{ margin: "8px 0" }}
            onClick={async () => {
              await setConsignee(null);
              await setPopupConsigneeVisible(true);
            }}
          >
            Thêm người nhận
          </Button>
          <br />
        </div>
      );
    }

    return (
      <div>
        <Row style={{ margin: "8px 0" }}>
          <Input
            placeholder="Số điện thoại KH"
            prefix={<PhoneTwoTone />}
            style={{ width: 200 }}
            value={phone}
            onPaste={onPhonePaste}
            onChange={onPhoneChanged}
          />
          <Button className="btn-search" type="primary" onClick={onSearch}>
            Tìm
          </Button>
        </Row>

        {customer ? (
          <div>
            <h3>{customer ? "KH: " + customer.username : ""}</h3>
            <Button
              type="primary"
              className="add-consignee"
              icon={<PlusOutlined />}
              style={{ margin: "8px 0" }}
              onClick={async () => {
                await setConsignee(null);
                await setPopupConsigneeVisible(true);
              }}
            >
              Thêm người nhận
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  };

  const [phone, setPhone] = useState(null);
  const [customer, setCustomer] = useState(null);
  const onPhoneChanged = (e) => {
    let value = phoneMask(e.target.value);
    console.log(value);
    setPhone(value);
  };

  const onPhonePaste = (e) => {
    let value = e.clipboardData.getData("Text");
    value = value.replace(/[^\d]/g, "");
    value = phoneMask(value);
    setPhone(value);
  };

  const phoneMask = (value) => {
    //(520) 204-4208
    var x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
  };

  const onSearch = async () => {
    _getConsigneeByCustomer(phone);
  };

  const _getConsigneeByCustomer = (phone) => {
    getConsigneeByCustomer(phone)
      .then((res) => {
        let { customer, consignees } = res.data;
        setCustomer(customer);

        if (!consignees || consignees.length === 0) {
          message.error("Không có người nhận");
          return;
        }
        setConsignees(
          res.data.consignees.map((e, idx) => {
            return { index: idx, ...e };
          })
        );
      })
      .catch((err) => {
        setCustomer(null);
        setConsignee(null);
        message.error(err.response.data.message);
      });
  };

  return (
    <Col className="consignee">
      {roleViews()}

      <div>
        <CreateShippingModal
          customer={customer}
          consignee={consignee}
          visible={popupShippingVisible}
          onCancel={() => {
            setPopupShippingVisible(false);
          }}
        />
        <ConsigneeModal
          customer={customer}
          data={consignee}
          visible={popupConsigneeVisible}
          onCreate={onPopupConsigneeCreate}
          onCancel={() => {
            setPopupConsigneeVisible(false);
          }}
        />
        <Table
          className="consignee-table"
          rowKey="index"
          {...state}
          pagination={false}
          columns={columns}
          loading={loading}
          dataSource={consignees ?? []}
          scroll={{ y: "80vh" }}
        />
      </div>
    </Col>
  );
}
