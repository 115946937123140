import React from "react";
import "../style/dashboard.css"
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

export default function Home() {
    const {user} = useSelector((state) => state.user);

    function render() {
        if (!user) return (<Redirect to='/login'/>)
        return <Redirect to='/landing'/>
        // switch (user.role) {
        //     case 2:
        //         return <Redirect to='/shipping-queue'/>
        //     case 4:
        //         return <Redirect to='/shipping'/>
        //     case 3:
        //         return <Redirect to='/shipping-queue'/>
        //     default:
        //         return <Redirect to='/statistic'/>
        // }
    }

    return render();
}
