import React, {useState} from 'react';
import {
    Form,
    Input,
    Select,
    InputNumber, Divider, Spin, Modal, Col, Row, Upload,
} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {attemptPostShipping} from "../../../store/thunks/shipping";
import Text from "antd/es/typography/Text";
import {PlusOutlined} from "@ant-design/icons";
import http from "../../../services/httpService";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const CreateShippingModal = ({customer, consignee, visible, onCancel}) => {
    
    const [form] = Form.useForm();
    const layout = {
        labelCol: {span: 4},
        wrapperCol: {span: 16},
    };

    const dispatch = useDispatch();
    const {branches, loading} = useSelector(state => state.branch)

    const validateMessages = {
        required: '${label} không được bỏ trống!',
    };

    const [previewState, setPreviewState] = useState({
        visible: false,
        image: '',
        title: '',
    })

    const handleCancel = () => setPreviewState(() => previewState.visible = false);

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        let newState = {
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        }
        setPreviewState(() => newState)
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    function branchOptions() {
        let opts = []
        branches?.forEach(e => {
            if (e.type !== "VN") {
                opts.push(<Select.Option key={e._id} value={e._id}>
                    <Col>
                        <Text strong>{e.name}</Text>
                        <br/>
                        <Text>{e.address}</Text>
                    </Col>
                </Select.Option>)
            }
        })
        return opts;
    }

    const getFilesName = () => form.getFieldValue('photos')?.map(e => {
        return e.response.image.split(/([\\/])/g).pop()
    })

    return (
        <Spin spinning={false}>
            <Modal
                width={1000}
                destroyOnClose={true}
                visible={visible}
                centered
                title={`Gửi cho ${consignee?.name}`}
                okText="Tạo"
                cancelText="Hủy"
                onCancel={() => {
                    let fileList = getFilesName();
                    if (fileList?.length > 0)
                        http.post('/remove-uploaded', fileList)
                            .then(() => {
                            })
                    form.resetFields();
                    onCancel();
                }}
                onOk={() => {

                    form.validateFields()
                        .then(async (values) => {
                            values.photos = getFilesName();
                            const postData = {
                                ...values,
                                customer: customer,
                                consignee: consignee
                            };
                            dispatch(attemptPostShipping(postData));
                            form.resetFields();
                            onCancel();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}>

                <Row gutter={10}>
                    <Col sm={8} md={4} lg={4} style={{textAlign: 'right'}}>Tên:</Col>
                    <Col sm={12} md={20} lg={20}>
                        <div><b>{consignee?.name}</b></div>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col sm={8} md={4} lg={4} style={{textAlign: 'right'}}>Địa chỉ:</Col>
                    <Col sm={16} md={20} lg={20}>
                        <div>{consignee?.address}</div>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col sm={8} md={4} lg={4} style={{textAlign: 'right'}}>SĐT:</Col>
                    <Col sm={16} md={20} lg={20}>
                        <div>{consignee?.phone}</div>
                    </Col>
                </Row>
                <Divider orientation='left'>Thông tin đơn hàng:</Divider>
                <Form
                    {...layout}
                    form={form}
                    name="form_in_modal"
                    initialValues={{branch: branches[0]?._id}}
                    validateMessages={validateMessages}>

                    <Form.Item name={['meta', 'box_count_1']}
                               label="Số lượng thùng"
                               rules={[{required: true}]}>
                        <InputNumber min={1}/>
                    </Form.Item>
                    <Form.Item name='description' label="Mô tả"
                               help="Mô tả chi tiết thùng hàng của bạn"
                               rules={[{required: true}]}>
                        <Input.TextArea style={{minHeight: 200}}/>
                    </Form.Item>

                    <Form.Item name='photos'
                               label="Ảnh đính kèm"
                               valuePropName="fileList"
                               getValueFromEvent={normFile}
                               rules={[{required: false}]}>

                        <Upload
                            name="photo"
                            action="/api/upload"
                            listType="picture-card"
                            onPreview={handlePreview}>
                            {/*{fileList.length >= 4 ? null : }*/}
                            <div>
                                <PlusOutlined/>
                                <div style={{marginTop: 8}}>Upload</div>
                            </div>
                        </Upload>

                    </Form.Item>
                    {/*<Form.Item name={['meta', 'message']}*/}
                    {/*           label="Ghi chú (option)"*/}
                    {/*           help="Tạo ghi chú tới đơn vị vận chuyển">*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}

                    <Form.Item name='branch'
                               label="Chi nhánh"
                               help="Chọn chi nhánh gửi đi"
                               rules={[{required: true}]}>
                        <Select>
                            {branchOptions()}
                        </Select>
                    </Form.Item>
                </Form>

            </Modal>

            <Modal
                visible={previewState.visible}
                title={previewState.title}
                footer={null}
                onCancel={handleCancel}>
                <img alt="" style={{width: '100%'}} src={previewState.image}/>
            </Modal>
        </Spin>

    );
};

export default CreateShippingModal;
