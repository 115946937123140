import {Button, Col, Form, Input, message, Modal, Spin, Tag} from "antd";
import React, {useEffect, useState} from "react";
import {getEditableTransport, updateBoxes} from "../../../api";
import {Util} from "../../../utils";

const EditBoxesModal = ({data, visible, onClose}) => {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false)
    const [boxesOfReceipt, setBoxesOfReceipt] = useState([])

    const [readyBoxes, setReadyBoxes] = useState([])
    const [otherReadyBoxes, setOtherReadyBoxes] = useState([])
    const [transportedBoxes, setTransportedBoxes] = useState([])

    let newBoxes = []

    useEffect(() => {
        if (visible) {
            setLoading(true)
            getEditableTransport(data.history.map(e => e.id), data.consignee.region)
                .then(res => {
                    let {readyBoxes, transported} = res.data;

                    setReadyBoxes(readyBoxes.filter(e => e.region === data.consignee.region))
                    setOtherReadyBoxes(readyBoxes.filter(e => e.region !== data.consignee.region))
                    setTransportedBoxes(transported)
                    let boxOfReceipt = readyBoxes.filter(e => e.tracking_ids.indexOf(data.tracking_id) > -1)
                    boxOfReceipt.push(...transported.filter(e => e.tracking_ids.indexOf(data.tracking_id) > -1))
                    setBoxesOfReceipt(boxOfReceipt)

                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                })
        }
    }, [visible])


    const initBoxes = (boxes) => {
        return boxes?.map(box => {
            // let isCurrentBox = selectedBoxes.indexOf(box.box_num) > -1;
            return (
                <Tag
                    // style={{
                    //     userSelect: 'none',
                    //     border: `1px solid lightgrey`,
                    //     background: isCurrentBox ? '' : 'lightgrey',
                    //     width: '30px',
                    //     height: 'auto',
                    //     textAlign: 'center',
                    //     lineHeight: '30px',
                    //     // pointerEvents: !isDeliveredBox && isTransportedBox ? 'auto' : 'none',
                    // }}
                    key={box.box_num}
                    // checked={true}
                    // onChange={checked => onBoxSelection(box.box_num, checked)}
                >
                    {box.box_num}
                </Tag>
            )
        })
    }

    return !data ? <></> : (
        <Spin spinning={loading}>
            <Modal
                centered
                destroyOnClose={true}
                visible={visible}
                title={`Tracking number ${data.branch.prefix} - ${data.tracking_id}`}
                footer={[
                    <Button key={2} onClick={() => {
                        onClose();
                    }}>Đóng</Button>,
                    <Button key={3} type="primary" onClick={() => {
                        let currentBoxes = boxesOfReceipt.map(b => b.box_num)
                        let {added, removed} = Util.compare(currentBoxes, newBoxes) //compare to new input box number

                        let errorBoxes = [];
                        otherReadyBoxes.forEach(e => {
                            if (added.includes(e.box_num.toString())) errorBoxes.push(e.box_num)
                        })
                        if (errorBoxes.length > 0) {
                            message.error("Thùng số [" + errorBoxes + "] khác khu vực")
                            return;
                        }

                        let addToBoxIds = []
                        let removeFromBoxIds = []

                        readyBoxes.forEach(b => {
                            let addIndex = added.indexOf(b.box_num.toString())
                            if (addIndex > -1) {
                                addToBoxIds.push(b._id)
                                added.splice(addIndex, 1);
                            }

                            let removeIndex = removed.indexOf(b.box_num.toString())
                            if (removeIndex > -1) {
                                removeFromBoxIds.push(b._id)
                                removed.splice(removeIndex, 1);
                            }
                        })

                        let addToTransportedBoxIds = []
                        let removeFromTransportedBoxIds = []
                        let addTransportedBoxNum = []
                        let removeTransportedBoxNum = []
                        transportedBoxes.forEach(b => {
                            let addIndex = added.indexOf(b.box_num.toString())
                            if (addIndex > -1) {
                                addTransportedBoxNum.push({
                                    box_num: b.box_num,
                                    history_id: b.history_id
                                })
                                addToTransportedBoxIds.push(b._id)
                                added.splice(addIndex, 1)
                            }

                            let removeIndex = removed.indexOf(b.box_num.toString())
                            if (removeIndex > -1) {
                                removeTransportedBoxNum.push({
                                    box_num: b.box_num,
                                    history_id: b.history_id
                                })
                                removeFromTransportedBoxIds.push(b._id)
                                removed.splice(removeIndex, 1);
                            }
                        })


                        let modify = {...data};
                        addTransportedBoxNum.forEach(e => {
                            let index = modify.history.findIndex(m => m.id === e.history_id)
                            modify.history[index].boxes.push(e.box_num)
                        })
                        removeTransportedBoxNum.forEach(e => {
                            let index = modify.history.findIndex(m => m.id === e.history_id)
                            modify.history[index].boxes = modify.history[index].boxes.filter(b => b !== e.box_num)
                        })

                        modify.meta.box_num = form.getFieldValue("box_num")
                        modify.meta.box_count_2 = newBoxes.length

                        let postData = {
                            shipping: modify,

                            boxIdsForAdd: [...addToBoxIds, ...addToTransportedBoxIds],
                            boxIdsForRemove: [...removeFromBoxIds, ...removeFromTransportedBoxIds],

                            newBoxes: added,
                        }

                        console.log(postData)

                        setLoading(true)
                        updateBoxes(postData)
                            .then(res => {
                                setLoading(false)
                            })
                            .catch(err => {
                                setLoading(false)
                            })
                        form.setFieldsValue({['box_count']: ''})
                        onClose();
                    }}>Cập nhật</Button>
                ]}
                onCancel={() => {
                    onClose();
                }}>
                {/*<Space>*/}
                {/*    <h4>Ngày vận chuyển:</h4>*/}
                {/*    <Select defaultValue={0}*/}
                {/*            style={{width: 150}}*/}
                {/*            onChange={onSelectDate}>*/}
                {/*        {transportDates.map((e, index) => {*/}
                {/*            let value = e == null ? 'Chưa vận chuyển' : moment(e.date).format("DD-MM-YYYY")*/}
                {/*            return <Option key={index} value={index}>{value}</Option>*/}
                {/*        })}*/}
                {/*    </Select>*/}
                {/*</Space>*/}
                {/*<br/>*/}
                <Col>
                    <h4>Mã thùng (hiện tại): {data.meta.box_num}</h4>
                </Col>
                <br/>
                <Col>
                    <h4>Số thùng của đơn:</h4>
                    {initBoxes(boxesOfReceipt)}
                </Col>
                <br/>
                <Col>
                    <h4>Số thùng (chưa vận chuyển):</h4>
                    {initBoxes(readyBoxes)}
                </Col>
                <br/>
                <Col>
                    <h4>Số thùng đã vận chuyển (cùng lịch):</h4>
                    {initBoxes(transportedBoxes)}
                </Col>
                <br/>
                <Form
                    form={form}
                    size="large">
                    <Form.Item
                        label={<h4>Nhập mã thùng mới</h4>}
                        // help='Không trùng với những thùng đã vận chuyển'
                        name="box_num"
                        rules={[
                            {
                                pattern: /^(([0-9]+-[0-9]+)|[0-9]+)+(,(([0-9]+-[0-9]+)|[0-9]+))+$|^[0-9]+$|^([0-9]+-[0-9]+)$/g,
                                message: "Mã thùng không hợp lệ",
                            },
                        ]}
                        onChange={(e) => {
                            newBoxes = Util.toBoxes(e.target.value)
                            let value = e.target.value.split(',');
                            let count = 0;
                            for (let i = 0; i < value.length; i++) {
                                if (!value[i].trim()) continue;

                                if (value[i].includes('-')) {
                                    let group = value[i].split('-')
                                    count += parseInt(group[1]) - parseInt(group[0]) + 1
                                } else {
                                    count++;
                                }
                            }
                        }}>
                        <Input placeholder="VD: 3 | 3,5 | 3-8 | 3-8,11" style={{width: '100%'}}/>
                    </Form.Item>
                </Form>
            </Modal>
        </Spin>
    );
};
export default EditBoxesModal;