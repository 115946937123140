import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {attemptLogout} from "../../store/thunks/auth";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(attemptLogout());
  }, []);

  return <p>Logout in progress</p>;
}
