import {
    LOADING,
    GET_TRANSPORT,
    SET_TRANSPORT, GET_TRANSPORT_DATES,
} from '../actions/types';

const initialState = {
    dates: [],
    boxes: [],
    tracking_ids: [],
    loading: false,
};

export default function transport(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_TRANSPORT:
            return {
                ...state,
                boxes: action.payload.boxes,
                tracking_ids: action.payload.tracking_ids,
                loading: false
            };
        case SET_TRANSPORT:
            return {
                ...state,
                loading: false
            };
        case GET_TRANSPORT_DATES:
            return {
                ...state,
                dates: action.payload,
                loading: false
            };
        default:
            return state;
    }
}