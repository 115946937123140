import React, { useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { attemptLogin } from "../../store/thunks/auth";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Form, Input, Row } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "../style/login.css";
import "suneditor/dist/css/suneditor.min.css";
import { Landing } from "./Landing"; // Import Sun Editor's CSS File
import logo from "../../logo2.png";
import MaskedInput from "antd-mask-input";
import TextLoop from "react-text-loop";

const Login = () => {
  const { isAuth } = useSelector((state) => state.user);
  const [serverError, setServerError] = useState("");
  const { info } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const location = useLocation();

  const onFinish = (values) => {
    dispatch(attemptLogin(values)).catch((error) => {
      if (error.response) {
        setServerError(error.response.data);
      }
    });
  };


  function splitNotice() {
    let notices = info?.notice_login?.split(/<p>|<\/p>|<br>|¤/)?.filter(e => e) ?? []
    return notices.map((e, i) => <div key={i} dangerouslySetInnerHTML={{ __html: e }} />)
  }

  function inputUser() {
    let isAdminLogin = location.pathname.includes("admin");

    return isAdminLogin ? (
      <Form.Item
        name="phone"
        validateStatus="error"
        rules={[
          {
            required: true,
            message: "Vui lòng nhập tên tài khoản để đăng nhập!",
          },
        ]}
      >
        <Input placeholder="Tên tài khoản" prefix={<UserOutlined className="site-form-item-icon" />} />
      </Form.Item>
    ) : (
      <Form.Item
        name="phone"
        validateStatus="error"
        help={serverError.phone}
        rules={[
          {
            required: true,
            message: "Vui lòng nhập số điện thoại để đăng nhập!",
          },
        ]}
      >
        <MaskedInput
          prefix={<UserOutlined className="site-form-item-icon" />}
          mask="(111) 111-1111"
          placeholder="Số điện thoại"
        />
      </Form.Item>
    );
  }

  return isAuth ? (
    <Redirect to="/home" />
  ) : (
    <Row type="flex" align="top">
      <Col className="login-side" xs={24} sm={10} md={10} lg={6}>
        <div
          style={{ width: "100%", textAlign: "center", marginBottom: "16px" }}
        >
          <img className="logo2" src={logo} alt="logo" />
        </div>
        <Form
          style={{ alignSelf: "center", background: "transparent" }}
          name="normal_login"
          className="login-form"
          size="default"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          {inputUser()}
          <Form.Item
            name="password"
            validateStatus="error"
            help={serverError.password}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập mật khẩu!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Mật khẩu"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ fontSize: 16, marginTop: 8 }}
            >
              Đăng nhập
            </Button>
            <br />
            <div style={{ marginTop: 8, fontSize: 16 }}>Hoặc&nbsp;
            <Link to="/register">đăng ký!</Link></div>
          </Form.Item>
        </Form>

        <div style={{ display: 'flex', margin: '80px 24px', }}>
          <TextLoop style={{ width: '100%' }} interval={3000} children={splitNotice()} springConfig={{ stiffness: 170, damping: 31 }} />
        </div>
      </Col>
      <Col xs={0} sm={14} md={14} lg={18}>
        <Landing />
      </Col>
    </Row>
  );
};
export default Login;
