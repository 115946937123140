import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import '../style/notice.css'

export default function Notices({className, notice}) {

    const [close, setClose] = useState(false)

    return <div className={`notice ${className} ${close ? 'hide' : ''}`}>
        <Marquee speed={40} style={{ overflow: 'hidden' }}>
            <div dangerouslySetInnerHTML={{ __html: notice }} />
        </Marquee>
        <div className="close-notice" onClick={()=>{
            setClose(true)
        }}>X</div>
    </div>
}