import {Card, Checkbox, Col, Divider, Form, Image, Input, Modal, Row} from "antd";
import React from "react";

const ShippingDetailModal = ({data, visible, onClose}) => {

    return !data ? <></> : (
        <Modal
            centered
            destroyOnClose={true}
            visible={visible}
            style={{minWidth: '60%'}}
            title={`Tracking number ${data.branch.prefix} - ${data.tracking_id}`}
            footer={null}
            onCancel={() => {
                onClose();
            }}>

            <Row gutter={8}>
                <Col sm={24} md={16} lg={16}>
                    <Card title={<b>Đơn hàng:</b>} style={{width: '100%'}}>
                        <Row gutter={4}>
                            <Col sm={6}>Số lượng thùng:</Col>
                            <Col>{data.meta.box_count_1}</Col>
                        </Row>
                        <Row gutter={4}>
                            <Col sm={6}>Mô tả:</Col>
                            <Col sm={18}> {data.description} </Col>
                        </Row>
                        <Row gutter={4}>
                            <Col sm={6}>Chi nhánh:</Col>
                            <Col>{data.branch.name}</Col>
                        </Row>
                        <Row gutter={4}>
                            <Col sm={6}>Ảnh:</Col>
                            <Image.PreviewGroup>
                                {data.photos?.map((e, index) => <Image
                                    key={index}
                                    width={150}
                                    src={e}
                                />)}
                            </Image.PreviewGroup>
                        </Row>
                    </Card>

                    <Row gutter={8}>
                        <Col style={{width: '100%', marginTop: 8}} sm={24} md={12} lg={12}>
                            <Card style={{height: '100%'}} title={<b>Người gửi:</b>}>
                                <Row>
                                    <Col sm={6}>Tên:</Col>
                                    <Col>{data.sender.username}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>SĐT:</Col>
                                    <Col>{data.sender.phone}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>Địa chỉ:</Col>
                                    <Col>{data.sender.address}</Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col style={{width: '100%', marginTop: 8}} sm={24} md={12} lg={12}>
                            <Card title={<b>Người nhận:</b>}>
                                <Row>
                                    <Col sm={6}>Tên:</Col>
                                    <Col>{data.consignee.name}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>SĐT:</Col>
                                    <Col>{data.consignee.phone}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>Địa chỉ:</Col>
                                    <Col>{data.consignee.address}</Col>
                                </Row>
                                <br/>
                                <div style={{color: 'red'}}>{data.meta.receive_at_office ? 'Nhận tại văn phòng HCM' : ''}</div>
                            </Card>
                        </Col>
                    </Row>

                </Col>

                <Col sm={24} md={8} lg={8} style={{height: 'auto', width: '100%'}}>
                    <Card title={<b>Thông tin thanh toán</b>} style={{width: '100%', height: '100%'}}>
                        <Row>
                            <Col sm={10}>Tổng thùng vận chuyển:</Col>
                            <Col sm={14}>{data.meta.box_count_2}</Col>
                        </Row>
                        <Row>
                            <Col sm={10}>Mã thùng:</Col>
                            <Col sm={14}>{data.meta.box_num}</Col>
                        </Row>
                        <Divider/>
                        <Row>
                            <Col sm={10}>Trọng lượng (lbs):</Col>
                            <Col sm={14}>{data.meta.total_weight}</Col>
                        </Row>
                        <Row>
                            <Col sm={10}>Đơn giá ($/lb): </Col>
                            <Col sm={14}>{data.meta.unit_price}</Col>
                        </Row>
                        <Row>
                            <Col sm={10}>Phụ thu ($): </Col>
                            <Col sm={14}>{data.meta.tax}</Col>
                        </Row>
                        <Divider/>
                        <Row style={{color:'red'}}>
                            <Col sm={10}><b>Thành tiền ($): </b></Col>
                            <Col sm={14}><b>{data.meta.total_amount}</b></Col>
                        </Row>
                        {/*<Checkbox checked={data.meta.is_paid}/>*/}
                    </Card>
                </Col>
            </Row>
        </Modal>
    );
};
export default ShippingDetailModal;