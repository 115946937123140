import React, {useContext, useEffect, useRef, useState} from 'react';
import {Form, Input} from 'antd';

const EditableContext = React.createContext(null);

export const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export const EditableCell = ({
                                 title,
                                 editable,
                                 children,
                                 dataIndex,
                                 record,
                                 handleSave,
                                 ...restProps
                             }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    let originValue = null;

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        originValue = record[dataIndex]
        form.setFieldsValue({
            [dataIndex]: originValue,
        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            if (values[dataIndex].toString() !== originValue?.toString())
                handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            pattern: /^[+-]?([0-9]*[.])?[0-9]+$/g,
                            message: `${title} không hợp lệ.`,
                        },
                    ]}
                >
                    <Input style={{textAlign: 'right', paddingRight: 24,}} placeholder='0' ref={inputRef}
                           onPressEnter={save} onBlur={save}/>
                </Form.Item>
            )
            : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24,
                        color: record[dataIndex] > 0 ? 'black' : 'grey'
                    }}
                    onDoubleClick={toggleEdit}
                >{record[dataIndex] ?? 0} </div>
            );
    }

    return <td {...restProps}>{childNode}</td>;
};