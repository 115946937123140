export const LOADING = "GET_SHIPPING";
export const GET_SHIPPING = "GET_SHIPPING";
export const POST_SHIPPING = "POST_SHIPPING";
export const APPROVAL_SHIPPING = "APPROVAL_SHIPPING";
export const DONE_SHIPPING = "DONE_SHIPPING";
export const READY_SHIPPING = "READY_SHIPPING";
export const TRANSPORT_HISTORY = "TRANSPORT_HISTORY";

export const loading = (loading) => {
    return {
        type: LOADING,
        loading: loading
    }
};

export function listShipping(data) {
    return {
        type: GET_SHIPPING,
        data: data
    };
}

export function postShipping() {
    return {
        type: POST_SHIPPING,
    };
}

export function approvalShipping() {
    return {
        type: APPROVAL_SHIPPING,
    };
}

export function doneShipping() {
    return {
        type: DONE_SHIPPING,
    };
}

export function readyShipping(data) {
    return {
        type: READY_SHIPPING,
        data: data
    };
}

export function transportHistory(data) {
    return {
        type: TRANSPORT_HISTORY,
        data: data
    };
}