import {
    APPROVAL_SHIPPING,
    DONE_SHIPPING,
    GET_SHIPPING,
    LOADING,
    POST_SHIPPING,
    READY_SHIPPING,
    TRANSPORT_HISTORY,
} from "../actions/shipping";

const initialState = {
    ready_boxes: [],
    ready_cargos: [],
    history: [],
    ready: [],
    loading: false,
    message: ""
};

export default function shipping(state = initialState, action) {
    switch (action.type) {
        case GET_SHIPPING:
            return {
                loading: false,
                data: action.data
            };
        case READY_SHIPPING:
            return {
                loading: false,
                ready_boxes: action.data.boxes,
                ready_cargos: action.data.cargos,
            };
        case TRANSPORT_HISTORY:
            return {
                loading: false,
                history: action.data
            };
        case POST_SHIPPING:
            return {
                loading: false,
            };
        case APPROVAL_SHIPPING:
            return {
                loading: false,
            };
        case DONE_SHIPPING:
            return {
                loading: false,
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state;
    }
}