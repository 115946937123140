import {Button, Card, Checkbox, Col, Form, Image, Input, InputNumber, message, Modal, Row, Spin, Tooltip} from "antd";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {putShipping} from "../../api";

const ShippingApprovalModal = ({data, visible, onClose}) => {

    const [form] = Form.useForm();
    const {user} = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false)
    const [enableNote, setEnableNote] = useState(null)
    let noteValue = 'Nhận tại văn phòng:\n203 Nam Kỳ Khởi Nghĩa P7, Q3, TP.HCM.\nAnh Sơn - 0903021115'

    const handleTotal = (_, values) => {
        values.total_amount = (values.total_weight ?? 0) * (values.unit_price ?? 0) + (values.tax ?? 0);
        form.setFieldsValue(values)
    }

    function isNotEmpty(value) {
        return value && value !== 0;
    }

    function initFieldsValue() {
        let values = form.getFieldsValue()
        return {
            box_num: data?.meta.box_num ?? values.box_num,
            box_count_2: data?.meta.box_count_2 ?? values.box_count_2,
            total_weight: data?.meta.total_weight ?? values.total_weight,
            unit_price: data?.meta.unit_price ?? values.unit_price,
            tax: data?.meta?.tax ?? values.tax ?? 0,
            total_amount: data?.meta?.total_amount ?? values.total_amount,
            message: data?.meta?.message ?? values.message ?? noteValue,
            receive_at_office: data?.meta?.receive_at_office ?? false,
            is_paid: data?.meta?.is_paid,
            description: data?.description,
        }
    }

    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initFieldsValue())
        }
    }, [visible])

    return !data ? <></> : (
        <Spin spinning={loading}>
            <Modal
                centered
                destroyOnClose={true}
                visible={visible}
                style={{minWidth: '80%'}}
                title={`Tracking number ${data.branch.prefix} - ${data.tracking_id}`}
                footer={[
                    // <Button key={1} style={{float: 'left'}} type="danger" onClick={async () => {
                    //     data.status = -1;
                    //     await dispatch(attemptApprovalShipping(data))
                    // }}>Hủy đơn</Button>,
                    <Button key={2} onClick={() => {
                        form.resetFields();
                        onClose();
                    }}>Hủy</Button>,
                    <Button key={3} type="primary" onClick={() => {
                        form.validateFields()
                            .then(async (values) => {
                                let modify = {...data}

                                modify.meta.box_num = values.box_num;
                                modify.meta.total_weight = values.total_weight;
                                modify.meta.unit_price = values.unit_price;
                                modify.meta.tax = values.tax;
                                modify.meta.total_amount = values.total_amount;
                                modify.meta.box_count_2 = values.box_count_2;
                                modify.meta.message = enableNote ? noteValue : null
                                modify.meta.is_paid = values.is_paid;
                                modify.meta.receive_at_office = values.receive_at_office;

                                if (user.role <= 2)
                                    modify.description = values.description;
                                if (isNotEmpty(modify.meta.box_num)
                                    && isNotEmpty(modify.meta.total_weight)
                                    && isNotEmpty(modify.meta.unit_price)
                                    // && isNotEmpty(modify.meta.tax)
                                    ) {
                                    modify.status = 1; //ready
                                } else {
                                    modify.status = 0; //pending
                                }
                                setLoading(true)
                                await putShipping(modify)
                                    .then((res) => {
                                        setLoading(false)
                                        message.success('Cập nhật thành công.')
                                        onClose(modify)
                                    })
                                    .catch((err) => {
                                        setLoading(false)
                                        message.error(err.response.data.message)
                                        // onClose()
                                        // form.setFieldsValue(values)
                                    });
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            })
                    }}>Cập nhật</Button>
                ]}
                onCancel={() => {
                    form.resetFields();
                    onClose();
                }}>

                <Form
                    form={form}
                    size="normal"
                    initialValues={{}}
                    onValuesChange={handleTotal}
                    name="form_in_modal">

                    <Row gutter={8}>
                        <Col sm={24} md={12} lg={12}>
                            <Card title={<b>Đơn hàng:</b>} style={{width: '100%'}}>
                                <Row gutter={4}>
                                    <Col sm={6}>Số lượng thùng:</Col>
                                    <Col>{data.meta.box_count_1}</Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col sm={6}>Mô tả:</Col>
                                    <Col sm={18}>
                                        {user.role > 2 ? <p style={{
                                                whiteSpace: 'pre-line',
                                                columnCount: 2,
                                                orphans: 99
                                            }}>{data.description}
                                            </p> :

                                            <Form.Item name='description' style={{width: '100%',}}>
                                                <Input.TextArea
                                                    style={{minHeight: 80, whiteSpace: 'pre-line', width: '100%'}}/>
                                            </Form.Item>
                                        }
                                    </Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col sm={6}>Chi nhánh:</Col>
                                    <Col>{data.branch.name}</Col>
                                </Row>
                                <Row gutter={4}>
                                    <Col sm={6}>Ảnh:</Col>
                                    <Image.PreviewGroup>
                                        {data.photos?.map((e, index) => <Image
                                            key={index}
                                            width={150}
                                            src={e}
                                        />)}
                                    </Image.PreviewGroup>
                                </Row>
                            </Card>

                            <Row gutter={8}>
                                <Col style={{width: '100%', marginTop: 8}} sm={24} md={12} lg={12}>
                                    <Card style={{height: '100%'}} title={<b>Người gửi:</b>}>
                                        <Row>
                                            <Col sm={6}>Tên:</Col>
                                            <Col>{data.sender.username}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>SĐT:</Col>
                                            <Col>{data.sender.phone}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>Địa chỉ:</Col>
                                            <Col>{data.sender.address}</Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col style={{width: '100%', marginTop: 8}} sm={24} md={12} lg={12}>
                                    <Card title={<b>Người nhận:</b>}>
                                        <Row>
                                            <Col sm={6}>Tên:</Col>
                                            <Col>{data.consignee.name}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>SĐT:</Col>
                                            <Col>{data.consignee.phone}</Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>Địa chỉ:</Col>
                                            <Col>{data.consignee.address}</Col>
                                        </Row>
                                        <br/>
                                        <Form.Item style={{marginBottom: '0'}} name='receive_at_office'
                                                   valuePropName='checked'>
                                            <Checkbox>Khách muốn nhận tại văn phòng</Checkbox>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>

                        <Col sm={24} md={12} lg={12}>
                            <Card title={<b>Thông tin thanh toán</b>} style={{width: '100%'}}>
                                <Row>
                                    <Col sm={5}>Số thùng:</Col>
                                    <Form.Item name="box_count_2">
                                        <Input disabled style={{
                                            backgroundColor: 'white',
                                            border: 'none',
                                            fontWeight: 'bold',
                                            color: '#ec275f'
                                        }}/>
                                    </Form.Item>
                                </Row>

                                <Row>
                                    <Col sm={5}>
                            <span> Mã thùng&nbsp;
                                <Tooltip style={{minWidth: 300}} title={
                                    <p>Hướng dẫn:<br/>
                                        - Điền số thùng <b>"X"</b> nếu có 1.<br/>
                                        - Điền số thùng <b>"1,3,..."</b> nếu có nhiều thùng.<br/>
                                        - Điền số thùng <b>"1-3"</b> ={">"} Các thùng: 1,2,3.<br/>
                                        - Điền số thùng <b>"1-3, 5"</b> ={">"} Các thùng: 1,2,3,5.<br/>
                                    </p>}>
                                <QuestionCircleOutlined/>
                                </Tooltip>
                            </span>
                                    </Col>
                                    <Form.Item
                                        name="box_num"
                                        rules={[
                                            {
                                                pattern: /^(([0-9]+-[0-9]+)|[0-9]+)+(,(([0-9]+-[0-9]+)|[0-9]+))+$|^[0-9]+$|^([0-9]+-[0-9]+)$/g,
                                                message: "Mã thùng không hợp lệ",
                                            },
                                        ]}
                                        onChange={(e) => {
                                            let values = e.target.value.split(',');
                                            let count = 0;
                                            for (let i = 0; i < values.length; i++) {
                                                if (!values[i].trim()) continue;

                                                if (values[i].includes('-')) {
                                                    let group = values[i].split('-')
                                                    count += parseInt(group[1]) - parseInt(group[0]) + 1
                                                } else {
                                                    count++;
                                                }
                                            }
                                            form.setFieldsValue({['box_count_2']: count})
                                        }}>
                                        <Input disabled={data.status >= 1} placeholder="VD: 3 | 3,5 | 3-8 | 3-8,11"
                                               style={{width: 300}}/>
                                    </Form.Item>
                                </Row>
                                <Row>
                                    <Col sm={5}>
                                        <Form.Item
                                            name="total_weight"
                                            help="Trọng lượng (lbs)"
                                            rules={[{required: true, message: 'Thiếu Trọng lượng'}]}>
                                            <InputNumber style={{width: '90%'}} placeholder="0.00" type="number"/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={1}>
                                        <div style={{lineHeight: '30px'}}>x</div>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Item
                                            name="unit_price"
                                            help="Đơn giá ($/lb)"
                                            rules={[{required: true, message: 'Thiếu unit price'}]}>
                                            <InputNumber style={{width: '90%'}} placeholder="0.00" type="number"/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={1}>
                                        <b style={{lineHeight: '30px'}}>+</b>
                                    </Col>
                                    <Col sm={5}>
                                        <Form.Item
                                            name="tax"
                                            help="Phụ thu ($)"
                                            rules={[{required: false, message: 'Thiếu phụ thu'}]}>
                                            <InputNumber style={{width: '90%'}} placeholder="0.00" type="number"/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={1}>
                                        <b style={{lineHeight: '30px'}}>=</b>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Item
                                            validateStatus="error"
                                            name="total_amount">
                                            <Input placeholder="0.00" disabled
                                                   style={{
                                                       backgroundColor: 'white',
                                                       border: 'none',
                                                       fontWeight: 'bold',
                                                       color: '#ec275f',
                                                       width: '100%'
                                                   }}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item
                                    label="Đã thanh toán"
                                    name="is_paid"
                                    valuePropName='checked'>
                                    <Checkbox/>
                                </Form.Item>
                            </Card>
                            {/*<br/>*/}
                            {/*<Form.Item name='receive_at_office' valuePropName='checked'>*/}
                            {/*    <Checkbox>Khách muốn nhận tại văn phòng</Checkbox>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item name='message'>*/}
                            {/*    <Input.TextArea*/}
                            {/*        disabled={enableNote === null ? !data?.meta?.message : !enableNote}*/}
                            {/*        style={{minHeight: 80, whiteSpace: 'pre-line',}}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            noteValue = e.target.value;*/}
                            {/*        }}/>*/}
                            {/*</Form.Item>*/}
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Spin>
    );
};
export default ShippingApprovalModal;