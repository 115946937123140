import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Modal,
  Popover,
  Row,
  Space,
  Statistic,
  Table,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  CarryOutOutlined,
  ContainerOutlined,
  DownloadOutlined,
  GoldFilled,
  InboxOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import BillForm from "../../shared/BillForm";
import Title from "antd/es/typography/Title";
import moment from "moment";
import { attemptAddTransportSchedule } from "../../../store/actions/transport_actions";
import {
  getShipping,
  getTransport,
  getTransportsData,
  updateWeight,
} from "../../../api";
import { EditableCell, EditableRow } from "./EditableCell";
import { Util } from "../../../utils";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

const ScheduleTransportedTable = () => {
  const state = {
    bordered: true,
    showHeader: true,
    scroll: undefined,
    hasData: true,
    tableLayout: "fixed",
  };

  const col_box_num = {
    title: "Số thùng",
    dataIndex: "box_num",
    sorter: (a, b) => a.box_num - b.box_num,
    width: 100,
  };

  const col_box_weight = {
    title: "Trọng lượng (Lbs)",
    dataIndex: "weight",
    width: 120,
    align: "right",
    editable: true,
    onCell: (record) => ({
      record,
      editable: true,
      dataIndex: "weight",
      title: "Trọng lượng",
      handleSave: onWeightChanged,
    }),
  };

  const onWeightChanged = (row) => {
    row.status = 0; //weight added
    updateBox(row, () => {
      const newData = [...boxes];
      const index = newData.findIndex((item) => row.box_num === item.box_num);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...row });
      setBoxes(newData);
      setTotalWeight(Util.sum(newData, "weight"));
    });
  };

  const col_tracking_numbers = {
    title: "Tracking No.(s)",
    dataIndex: ["box_num", "tracking_ids"],
    width: 300,
    render: (_, record) => {
      return (
        <Space>
          {record.tracking_ids.map((id) => (
            <Tag
              key={id}
              color="geekblue"
              style={{ cursor: "pointer" }}
              onClick={async () => {
                let req = await getShipping(id);
                if (req.data) {
                  await setCargoData(req.data);
                  setVisible(true);
                }
              }}
            >
              {String(id).padStart(6, "0")}
            </Tag>
          ))}
        </Space>
      );
    },
  };

  const col_status = {
    title: "Trạng thái",
    dataIndex: "status",
    width: 150,
    align: "center",
    render: (status) => {
      if (status === -1) {
        return (
          <Tag icon={<CarryOutOutlined />} color="error">
            {" "}
            Chưa cân
          </Tag>
        );
      }
      if (status === 0) {
        return (
          <Tag icon={<CarryOutOutlined />} color="processing">
            {" "}
            Sẵn sàng
          </Tag>
        );
      }
    },
  };

  const col_ready_date = {
    title: "Ngày đóng thùng",
    width: 150,
    align: "center",
    dataIndex: "ready_date",
    render: (value) => {
      return <>{moment(value).format("YYYY-MM-DD")}</>;
    },
  };

  const buildColumn = () => {
    let col = [];
    col.push(col_box_num);
    col.push(col_box_weight);
    col.push(col_tracking_numbers);
    col.push(col_ready_date);
    col.push(col_status);
    return col;
  };

  const dispatch = useDispatch();
  const { branchVN } = useSelector((state) => state.branch);
  const [loading, setLoading] = useState(true);
  const [tracking_ids, setTrackingIds] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [selectedBoxes, setSelectedBoxes] = useState([]);

  const [boxSelected, setSelectedRows] = useState([]);
  const rowSelection = {
    selectedRows: boxSelected,
    onChange: (value, record) => {
      setSelectedRows(record);
      setSelectedBoxes(record);
    },
    getCheckboxProps: (record) => ({
      disabled: !parseFloat(record.weight), // Column configuration not to be checked
    }),
  };

  useEffect(() => {
    fetchBoxes();
  }, [dispatch]);

  function fetchBoxes() {
    // if (boxes.length !== 0 && tracking_ids.length !== 0) return;
    setLoading(true);
    getTransport([-1, 0])
      .then((res) => {
        let shippingIds = new Set();
        res.data.forEach((e) => {
          e.tracking_ids.forEach((id) => shippingIds.add(id));
        });
        setBoxes(res.data);
        setTrackingIds([...shippingIds]);
        setTotalWeight(Util.sum(res.data, "weight"));
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  const [visible, setVisible] = useState(false);
  const [cargoData, setCargoData] = useState(null);

  const [transportDate, setTransportDate] = useState(undefined);
  const [schedulerVisible, setSchedulerVisible] = useState(false);

  const onAddSchedule = () => {
    let boxesToTrackingId = [];

    selectedBoxes.forEach((box) => {
      box.tracking_ids.forEach((id) => {
        let index = boxesToTrackingId.findIndex((e) => e.tracking_id === id);
        if (index > -1) {
          boxesToTrackingId[index].boxes.push(box.box_num);
        } else {
          boxesToTrackingId.push({
            tracking_id: id,
            boxes: [box.box_num],
          });
        }
      });
    });

    let data = {
      shipping: boxesToTrackingId,
      transport: selectedBoxes,
      date: transportDate,
    }
// console.log(data)
    dispatch(
      attemptAddTransportSchedule(data)
    ).then((res) => {
      setBoxes([]);
      setTrackingIds([]);
      setTotalWeight(0);
      setSelectedRows([]);
      setSelectedBoxes([]);
      fetchBoxes();
    });
  };

  function updateBox(box, onSuccess) {
    updateWeight(box)
      .then((res) => {
        onSuccess();
      })
      .catch((err) => {
        console.log("update weight error");
      });
  }

  const setScheduleButton = () => (
    <Popover
      content={
        <Space style={{ width: "100%" }} direction="vertical">
          <DatePicker
            size="large"
            style={{ width: "100%" }}
            format="YYYY-MM-DD"
            // disabledDate={(current) =>
            //   current < moment(new Date(), "YYYY-MM-DD").subtract(1, "day")
            // }
            onChange={(value) => {
              setTransportDate(value?.format("YYYY-MM-DD"));
            }}
          />
          <Row>
            <Button
              style={{ width: "45%" }}
              onClick={() => setSchedulerVisible(false)}
            >
              Hủy
            </Button>
            <div style={{ width: "10%" }} />
            <Button
              style={{ width: "45%" }}
              type="primary"
              disabled={transportDate === undefined}
              onClick={() => {
                onAddSchedule();
                setSchedulerVisible(false);
              }}
            >
              Duyệt
            </Button>
          </Row>
        </Space>
      }
      placement="bottomLeft"
      title={
        <Title style={{ marginBottom: 0 }} level={4}>
          Đặt lịch vận chuyển
        </Title>
      }
      trigger="click"
      visible={schedulerVisible}
      onVisibleChange={(value) =>
        setSchedulerVisible(boxSelected?.length === 0 ? false : value)
      }
    >
      <Button
        disabled={boxSelected?.length === 0}
        type="primary"
        icon={<PlusOutlined />}
      >
        Đặt lịch bay
      </Button>
    </Popover>
  );

  const [csvDate, setCsvDate] = useState(null);
  const [pickDate, showPickDate] = useState(false);

  const exportCsv = () => {
    getTransportsData()
      .then((res) => {
        let { boxes, receipts } = res.data;
        let ret = [];

        boxes.forEach((b) => {
          b.tracking_ids.forEach((id) => {
            let receipt = receipts.find((e) => (e.tracking_id = id));
            ret.push({
              "Số thùng": b.box_num,
              "Số Tracking": id,
              "Tên người gửi": receipt.sender.username,
              "Địa chỉ người gửi": receipt.sender.address,
              "SDT người gửi": receipt.sender.phone,
              "Tên người nhận": receipt.consignee.name,
              "Địa chỉ nhận": receipt.consignee.address,
              "SDT người nhận": receipt.consignee.phone,
              "Ngày vận chuyển": csvDate,
            });
          });
        });

        const sheet1 = XLSX.utils.json_to_sheet(ret);
        const wb = { Sheets: { sheet1: sheet1 }, SheetNames: ["sheet1"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(data, csvDate + ".xlsx");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportButton = () => {
    return (
      <Popover
        content={
          <Space style={{ width: "100%" }} direction="vertical">
            <DatePicker
              size="large"
              style={{ width: "100%" }}
              format="YYYY-MM-DD"
              // disabledDate={(current) =>
              //   current < moment(new Date(), "YYYY-MM-DD").subtract(1, "day")
              // }
              onChange={(value) => {
                setCsvDate(value?.format("YYYY-MM-DD"));
              }}
            />
            <Button
              disabled={!csvDate}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                exportCsv();
              }}
            >
              Duyệt
            </Button>
          </Space>
        }
        placement="bottomLeft"
        title={
          <Title style={{ marginBottom: 0 }} level={4}>
            Ngày vận chuyển
          </Title>
        }
        trigger="click"
        visible={pickDate}
        onVisibleChange={(value) => showPickDate(value)}
      >
        <Button
          icon={<DownloadOutlined />}
          type="primary"
          onClick={(e) => {
            showPickDate(true);
          }}
        >
          Export
        </Button>
      </Popover>
    );
  };

  return (
    <div>
      {
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 12px",
          }}
        >
          <Space>
            {setScheduleButton()}
            <table border="1" cellPadding="4" style={{ border: "lightgray" }}>
              <tbody>
                <tr>
                  <th>Số thùng</th>
                  <th>Trọng lượng</th>
                </tr>
                <tr>
                  <td>{selectedBoxes.length}</td>
                  <td>{Util.sum(selectedBoxes, "weight")}</td>
                </tr>
              </tbody>
            </table>
          </Space>

          <Space size="large">
            <Statistic
              prefix={<InboxOutlined />}
              title="Tổng thùng"
              value={boxes.length}
            />
            <Statistic
              prefix={<ContainerOutlined />}
              title="Tổng vận đơn"
              value={tracking_ids.length}
            />
            <Statistic
              prefix={<GoldFilled />}
              title="Trọng lượng"
              value={totalWeight}
            />
            {exportButton()}
          </Space>
        </div>
      }
      <br />
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        size="small"
        className="shipping-box-table"
        rowKey="box_num"
        {...state}
        columns={buildColumn()}
        loading={loading}
        dataSource={boxes ?? []}
        pagination={false}
        rowClassName={() => "editable-row"}
        rowSelection={rowSelection}
        scroll={{ y: "70vh" }}
      />

      <Modal
        title={String(cargoData?.tracking_id).padStart(6, "0")}
        centered
        visible={visible}
        okText=""
        footer={null}
        width={800}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        <BillForm data={cargoData} branchVN={branchVN} />
      </Modal>
    </div>
  );
};

export default ScheduleTransportedTable;
