import React from "react";
import {useDispatch, useSelector} from "react-redux";
import '../style/profile.css'

import {attemptUpdateUser,} from "../../store/thunks/auth";

import {Button, Col, Form, Input, Tooltip,} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import MaskedInput from 'antd-mask-input'

const formItemLayout = {
    labelCol: {
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        sm: {
            span: 16,
        },
    },
};


const Profile = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {user} = useSelector((state) => state.user);

    const onFinish = (values) => {
        dispatch(attemptUpdateUser(values))
    };

    React.useEffect(() => {
        form.setFieldsValue(user)
    })


    function inputPhone() {
        return user.role !== 1 
        ? (<Form.Item
            name="phone"
            label="Số điện thoại"
            validateStatus="warning"
            help="Số điện thoại này dùng để đăng nhập."
            rules={[
                {
                    required: true,
                    message: 'Vui lòng nhập số điện thoại của bạn!',
                },
            ]}
        >
            <MaskedInput mask="(111) 111-1111"  placeholder="(xxx) xxx-xxxx"/>
        </Form.Item>)
        : <></>
    }

    return (
        <Col md={16} lg={14} xl={8} className="profile-wrapper">
            {/*<Link className="back" to='/home'><LeftOutlined/>Trở về</Link>*/}
            <Form
                className="profile-form"
                {...formItemLayout}
                form={form}
                name="profile"
                onFinish={onFinish}
                scrollToFirstError>

                <Form.Item
                    name="username"
                    label={
                        <span> Họ & Tên&nbsp;
                            <Tooltip title="What do you want others to call you?">
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        </span>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Xin nhập họ và tên của bạn!',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                {inputPhone()}

                <Form.Item
                    name="address"
                    label="Địa chỉ"
                    rules={[
                        {
                            required: true,
                            message: 'Xin nhập địa chỉ của bạn!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    name="email"
                    label="E-mail (tùy chọn)"
                    rules={[
                        {
                            type: 'email',
                            message: 'Email không hợp lệ',
                            required: false,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item wrapperCol={24}>
                    <Button style={{float: 'right'}} type="primary" htmlType="submit">
                        Cập nhật
                    </Button>
                </Form.Item>
            </Form>
        </Col>
    );
};

export default Profile;