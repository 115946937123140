import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import "../style/register.css";

import { attemptRegister } from "../../store/thunks/auth";

import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import MaskedInput from "antd-mask-input";

const formItemLayout = {
  labelCol: {
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { isAuth } = useSelector((state) => state.user);
  const [serverError, setServerError] = useState("");

  const onFinish = (values) => {
    dispatch(attemptRegister(values)).catch((error) => {
      if (error.response) {
        setServerError(error.response.data);
      }
    });
  };

  return isAuth ? (
    <Redirect to="/home" />
  ) : (
    <Row align="middle" style={{ margin: "24px 0" }}>
      <Col xs={0} sm={2} md={4} lg={6} xl={8} />

      <Col xs={24} sm={20} md={16} lg={12} xl={8}>
        <Link className="back-login" to="/login">
          <LeftOutlined />
          Đăng nhập
        </Link>
        <Form
          className="register-form"
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="username"
            label="Họ & Tên"
            rules={[
              {
                required: true,
                message: "Xin nhập họ và tên của bạn!",
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Số điện thoại"
            validateStatus={serverError.phone ? "error" : ""}
            help={serverError.phone}
            rules={[
              {
                required: true,
                message: "Vui lòng nhập số điện thoại của bạn!",
              },
            ]}
          >
            <MaskedInput mask="(111) 111-1111" placeholder="(xxx) xxx-xxxx" />
          </Form.Item>

          <Form.Item
            name="password"
            label="Mật khẩu"
            rules={[
              {
                required: true,
                message: "Vui lòng điền mật khảu!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Mật khẩu xác nhận"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Vui lòng điền mật khẩu xác nhận!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="address"
            label="Địa chỉ"
            rules={[
              {
                required: true,
                message: "Xin nhập địa chỉ của bạn!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "Email không hợp lệ",
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Đăng ký
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col xs={0} sm={2} md={4} lg={6} xl={8} />
    </Row>
  );
};

export default Register;
