import React, {useEffect, useRef, useState} from "react";
import {Button, Col, message, Radio, Space, Statistic, Table} from 'antd';
import {useDispatch, useSelector} from "react-redux";

import {useReactToPrint} from "react-to-print";
import DeliveredConfirmModal from "./DeliveredConfirmModal";
import {
    branchVNShippingTableColumns,
    expandedRowRender,
    ON_SEARCH_CLEARED,
    ON_SEARCH_FIRED,
    setActionCallback,
    SHOW_DELIVERY_CONFIRM
} from "../../table_columns";
import moment from "moment";
import {ContainerOutlined, GoldOutlined, InboxOutlined, PrinterOutlined} from "@ant-design/icons";
import {getListShipping} from "../../../../api";
import BillForm from "../../../shared/BillForm";
import {Util} from "../../../../utils";
import {useLocation} from "react-router-dom";

const VNShippingTable = ({dates}) => {
    const componentRef = useRef();
    const handlePrintAll = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: async () => {
            await setPrintData(viewData.shipping)
        },
        onAfterPrint: () => {
            setPrintData(null)
        }
    });

    const handlePrintHCM = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: async () => {
            await setPrintData(viewData.shipping.filter(e => e.consignee.region === 0))
        },
        onAfterPrint: () => {
            setPrintData(null)
        }
    });

    const handlePrintOther = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: async () => {
            await setPrintData(viewData.shipping.filter(e => e.consignee.region === 1))
        },
        onAfterPrint: () => {
            setPrintData(null)
        }
    });

    const state = {
        bordered: true,
        showHeader: true,
        scroll: undefined,
        hasData: true,
        tableLayout: 'fixed',
    };

    const defaultViewData = {
        shipping: [],
        boxes: [],
        hcm_weight: 0,
        other_weight: 0
    }
    const {branchVN} = useSelector((state) => state.branch);
    const [deliveryConfirm, showDeliveryConfirm] = useState({visible: false, shipping: null});
    const [shipping, setShipping] = useState([]) //get by date - the original data
    const [currentHistory, setCurrentHistory] = useState(null)
    const [viewData, setViewData] = useState(defaultViewData)
    const [printData, setPrintData] = useState(null)

    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    setActionCallback((type, value, dataIndex) => {
        switch (type) {
            case SHOW_DELIVERY_CONFIRM:
                showDeliveryConfirm({visible: true, shipping: value})
                break;
            case ON_SEARCH_FIRED:
                let queryData = viewData.shipping.filter(item => item[dataIndex].toString().includes(value))
                updateViewData(queryData, currentHistory)
                break;
            case ON_SEARCH_CLEARED:
                updateViewData(shipping, currentHistory)
                break;
        }
    });

    useEffect(() => {
        if (dates && dates.length !== 0) {
            let lastHistory = dates[0]
            setCurrentHistory(lastHistory)
            getShipping(lastHistory)
        }
    }, [dispatch])


    function getShipping(_history) {
        if (!_history) {
            clearData()
            return
        }
        setLoading(true)
        getListShipping({tracking_ids: _history.tracking_ids.join(',')})
            .then((res) => {
                setShipping(res.data)
                updateViewData(res.data, _history)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                message.error('unexpected error')
            });
    }

    function onSelectDate(e) {
        let history = dates[e.target.value]
        setCurrentHistory(history)
        getShipping(history)
    }

    function updateViewData(data, history) {
        //boxCount
        let boxSet = new Set();
        let boxes = [];
        data.forEach(e => {
            let transported_boxes = e.history.filter(h => h.type === 'transport').map(t => t.boxes).flat()
            transported_boxes.forEach(b => boxSet.add(b))
        })
        history.boxes.forEach(b => {
            if (boxSet.has(b)) boxes.push(b)
        })
        setViewData({
            shipping: data,
            boxes: boxes,
            ...Util.sumShippingWeight(data)
        })
    }

    function clearData() {
        setViewData(defaultViewData)
    }


    return <div>
        <DeliveredConfirmModal
            visible={deliveryConfirm.visible}
            shipping={deliveryConfirm.shipping}
            boxesOfDate={viewData.boxes}
            onClose={() => {
                showDeliveryConfirm({visible: false, shipping: null});
            }}
        />
        {
            <div style={{display: "flex", justifyContent: 'space-between'}}>
                <div style={{display: "none"}}>
                    <div ref={componentRef}>
                        {printData?.map((receipt, i) => <BillForm key={i} data={receipt} branchVN={branchVN}/>)}
                    </div>
                </div>
                <Space size={20}>
                    <Button icon={<PrinterOutlined/>} type='danger' onClick={handlePrintAll}>In tất cả</Button>
                    <Button icon={<PrinterOutlined/>} type='primary' onClick={handlePrintHCM}>In HCM</Button>
                    <Button icon={<PrinterOutlined/>} type='primary' ghost onClick={handlePrintOther}>In tỉnh</Button>
                </Space>
                {/*<Search*/}
                {/*    style={{width: 150, alignSelf: 'center'}}*/}
                {/*    loading={loading}*/}
                {/*    placeholder="DD-MM-DDDD"*/}
                {/*    onSearch={value => {*/}
                {/*        let date = moment(value, 'DD-MM-YYYY', true);*/}
                {/*        if (date.isValid()) {*/}
                {/*            onSelectDate(date.format('YYYY-MM-DD'))*/}
                {/*        } else {*/}
                {/*            message.error('Ngày không hợp lệ')*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    enterButton*/}
                {/*/>*/}

                <Space size={50}>
                    <Statistic loading={loading} prefix={<InboxOutlined/>} title="Tổng thùng"
                               value={viewData.boxes.length}/>
                    <Statistic loading={loading} prefix={<ContainerOutlined/>} title="Vận đơn"
                               value={viewData.shipping.length}/>
                    <Statistic loading={loading} prefix={<GoldOutlined/>} title="HCM (Lbs)"
                               value={viewData.hcm_weight}/>
                    <Statistic loading={loading} prefix={<GoldOutlined/>} title="Tỉnh (Lbs)"
                               value={viewData.other_weight}/>
                </Space>

            </div>
        }

        <br/>
        <Space align="start">
            <Col>
                <h4>Ngày vận chuyển</h4>
                <Radio.Group style={{minWidth: 120,}} defaultValue={0} onChange={onSelectDate}>
                    {dates.map((e, i) => {
                        let value = moment(e.date).format("DD-MM-YYYY")
                        return <Radio style={{width: 120, margin: '4px 0'}} key={i}
                                      value={i}>{value}</Radio>
                    })}
                </Radio.Group>
            </Col>
            <Table
                size='small'
                className="shipping-table"
                rowKey='tracking_id'
                {...state}
                pagination={false}
                columns={branchVNShippingTableColumns}
                loading={loading}
                dataSource={viewData.shipping}
                expandable={{expandedRowRender}}
                scroll={{y: '70vh'}}/>
        </Space>
    </div>;
}

export default VNShippingTable;
