function expandBoxes(range) {
    return range.replace(/([0-9]+-[0-9]+)/g, function (match) {
        const range = match.split('-')
        let newStr = '';
        let start = parseInt(range[0]);
        const end = parseInt(range[1]);
        while (start <= end) {
            newStr += (start++).toString() + ','
        }
        return newStr.slice(0, -1);
    });
}

function toBoxes(range) {
    return expandBoxes(range).split(',').map(value => parseInt(value))
}

function toBoxesString(range) {
    if(!range) return

    let boxes = expandBoxes(range)
    if(boxes.length <= 1) return

    return "Box(es) Number: " + boxes.split(',').join(', ')
}

function inRange(range, value) {
    return expandBoxes(range).includes(value);
}

function diff(arr1, arr2) {
    if (arr1.length > arr2.length)
        return arr1.filter(x => !arr2.includes(x));
    return arr2.filter(x => !arr1.includes(x));
}

function sum(arr, prop) {
    return arr.reduce((accum, item) => accum + parseFloat(item[prop] ?? 0), 0)
}

function sumShippingWeight(arr) {
    let hcm_weight = 0.0;
    let other_weight = 0.0;
    arr.forEach(e => {
        if (e.consignee.region === 0) {
            hcm_weight += e.meta.total_weight;

        } else {
            other_weight += e.meta.total_weight;
        }
    })
    return {hcm_weight: hcm_weight, other_weight: other_weight}
}

function countUnique(arr, prop) {
    let set = new Set()
    arr.forEach(item => set.add(...item[prop]))
    return set.size
}

function breakLine(str, charsPerLine = 32) {
    let words = str.split(' ')
    let lengths = [];
    for (let i = 0; i < words.length; i++)
        lengths.push(words[i].replace(/<.+>/g, '').replace(/&.+;/g, ' ').length);

    let line = [], offset = 0, output = [];
    for (let i = 0; i < words.length; i++) {
        if (offset + (lengths[i] + line.length - 1) < charsPerLine) {
            line.push(words[i]);
            offset += lengths[i];
        } else {
            output.push(line.join(' '));
            offset = 0;
            line = [];
            i -= 1;
            charsPerLine = 48
        }
        if (i === words.length - 1)
            output.push(line.join(' '));
        
    }
    return output
}

function ltrim(char, str) {
    if (str.slice(0, char.length) === char) {
        return ltrim(char, str.slice(char.length));
    } else {
        return str;
    }
}

Number.prototype.format = function (n, x) {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

Array.prototype.update = function (newObj, key) {
    return this.map(obj => newObj[key] === obj[key] ? newObj : obj);
}


function compare(o, n) {
    let objO = {}, objN = {};
    for (let i = 0; i < o.length; i++) {
        objO[o[i]] = 1;
    }
    for (let i = 0; i < n.length; i++) {
        objN[n[i]] = 1;
    }

    let a = [];
    let r = [];

    for (let i in objO) {
        if (i in objN) {
            delete objN[i];
        } else {
            r.push(i);
        }
    }
    for (let i in objN) {
        a.push(i);
    }
    return {added: a, removed: r};
}

function getRanges(array) {
    const a = array.sort((a, b) => parseInt(a) - parseInt(b));
    let min = a[0], last = a[0]
    const result = a.reduce((arr, n, i) => {
        if (n - last > 1) {
            if (min === last) arr.push("" + min)
            else arr.push([min, last].join("-"))

            min = n
        }
        if (i === a.length - 1) {
            if (min === n) arr.push("" + min)
            else arr.push([min, n].join("-"))
        }
        last = n
        return arr
    }, [])
    return result.join(',')
    // return array.reduce((l => (r, v, i, a) => {
    //     if (l[1] > v) return r;
    //     r.push(l[1] === v
    //         ? (r.pop(), l.join('-'))
    //         : (l = [v, v]).slice(0, 1).toString()
    //     );
    //     l[1]++;
    //     return r;
    // })([]), []).join(',');
}

function getTextFormHtml(html) {
    return html.replace(/<[^>]+>/g, '')
}

function getThumbnail(html) {
    let matches = html.match(/<img [^>]*src="[^"]*"[^>]*>/gm)
    return !matches
        ? 'https://cometdelivery.com/assets/img/international.jpg'
        : matches[0].replace(/.*src="([^"]*)".*/, '$1');
}



export const Util = {
    expandBoxes,
    ltrim,
    breakLine,
    diff,
    sum,
    sumShippingWeight,
    countUnique,
    toBoxes,
    toBoxesString,
    inRange,
    compare,
    getRanges,
    getTextFormHtml,
    getThumbnail,
}