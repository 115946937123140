import React, {useEffect, useState} from "react";
import {Button, Card, DatePicker, Space, Statistic} from 'antd';
import {getStatistic, getStatisticRange} from "../../../api";
import {
    CaretLeftOutlined,
    CaretRightOutlined,
    DownloadOutlined,
    FileDoneOutlined,
    GoldOutlined
} from "@ant-design/icons";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment";

const {RangePicker} = DatePicker;


export const ExportCSV = ({csvData, fileName, loading}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        if(!csvData) return

        const sheet1 = XLSX.utils.json_to_sheet(csvData.receipts);
        const sheet2 = XLSX.utils.json_to_sheet(csvData.boxes);

        const wb = {Sheets: {'receipts': sheet1, 'boxes': sheet2}, SheetNames: ['receipts', 'boxes']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button disabled={csvData === undefined || csvData?.length === 0} loading={loading} icon={<DownloadOutlined/>}
                type="primary"
                onClick={(e) => exportToCSV(csvData, fileName)}>Export</Button>
    )
}


export default function AdminDashboard() {
    // const [today, setDate] = useState(new Date());
    // const timeFormat = 'yyyy MMM DD  HH:mm:ss'

    const [loading, setLoading] = useState(false)
    const [statistic, setStatistic] = useState(null)
    const [exportRangeData, setExportRangeData] = useState({loading: false})
    const [exportMonthData, setExportMonthData] = useState({loading: false})
    const [exportQuarterData, setExportQuarterData] = useState({loading: false})
    const [exportYearData, setExportYearData] = useState({loading: false})

    const [monthStat, setMonthStat] = useState(null)
    const [quarterStat, setQuarterStat] = useState(null)
    const [yearStat, setYearStat] = useState(null)
    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])
    const [mIndex, setMIndex] = useState(0)
    const [yIndex, setYIndex] = useState(0)


    useEffect(() => {
        setLoading(true)
        getStatistic()
            .then(res => {
                let months = res.data.reverse()
                let years = getYearStat(months)
                setMonths(months)
                setYears(years)

                setMonthStat(months[mIndex]) //current month
                setYearStat(years[yIndex]) //current year

                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.response)
            })
    }, [])


    function statByQuarter(months) {
        let total_shipping_hcm = 0;
        let total_shipping_other = 0;
        let total_weight_hcm = 0;
        let total_weight_other = 0;
        let ret = []
        months.forEach(m => {
            if (moment(m).isSame("", "quarter")) {

            }
        })
    }

    function getYearStat(months) {
        let ret = []
        months.forEach(m => {
            let year = m.year_month.substring(0, 4)
            let index = ret.findIndex(y => y.year === year)
            if (index > -1) {
                ret[index].total_shipping_hcm += m.total_shipping_hcm;
                ret[index].total_shipping_other += m.total_shipping_other;
                ret[index].total_weight_hcm += m.total_weight_hcm;
                ret[index].total_weight_other += m.total_weight_other;
            } else {
                ret.push({
                    year: year,
                    total_shipping_hcm: m.total_shipping_hcm,
                    total_shipping_other: m.total_shipping_other,
                    total_weight_hcm: m.total_weight_hcm,
                    total_weight_other: m.total_weight_other,
                })
            }
        })
        return ret;
    }

    function norReceipts(data) {
        return data.map(e => {
            return {
                "Tracking number": e.tracking_id,
                "Tên người gửi": e.sender.username,
                "SDT người gửi": e.sender.phone,
                "Địa chỉ người gửi": e.sender.address,
                "Tên người nhận": e.consignee.name,
                "SDT người nhận": e.consignee.phone,
                "Địa chỉ nhận": e.consignee.address,
                "Ngày tạo": moment(e.send_date).format("YYYY-MM-DD"),
                "Ngày đóng thùng": moment(e.ready_date).format("YYYY-MM-DD"),
                "Ngày giao xong": moment(e.done_date).format("YYYY-MM-DD"),
                "Ngày vận chuyển": e.history.map(e => moment(e.date).format("YYYY-MM-DD")).join(","),
                "Chi nhánh": e.branch.name,
                "Lbs": e.meta.total_weight,
                "Thanh toán": e.meta.total_amount,
                "Trạng thái": e.status === 0 ? "Chờ xác nhận" : e.status === 1 ? "Đang giao" : e.status === 2 ? "Đã giao" : "",
                "Đã thanh toán": e.meta.is_paid,
            }
        })
    }

    function norBoxes(data) {
        return data.map(e => {
            return {
                "Số thùng": e.box_num,
                "Ngày đóng thùng": moment(e.ready_date).format("YYYY-MM-DD"),
                "Ngày vận chuyển": moment(e.history_id.date).format("YYYY-MM-DD"),
                "Lbs": e.weight,
                "Tracking No.(s)": e.tracking_ids.join(','),
            }
        })
    }

    function getStatisticBy(range, callback) {
        setLoading(true)
        getStatisticRange(range)
            .then(res => {
                callback({
                    receipts: norReceipts(res.data.receipts),
                    boxes: norBoxes(res.data.boxes)
                })
                setLoading(false)
            })
            .catch((err) => {
                console.log('get statistic error', err)
                setLoading(false)
            })
    }

    function onRangeChanged(dates, dateStrings) {
        if (dateStrings.length === 2) {
            setExportRangeData({loading: true})
            getStatisticBy(dateStrings, (data) => setExportRangeData({
                name: `${dateStrings[0]} to ${dateStrings[1]}`,
                data: data,
                loading: false,
            }))
        }
    }

    function onMonthChanged(date, month) {
        if (month) {
            setExportMonthData({loading: true})
            let start = date.startOf("month").format("YYYY-MM-DD")
            let end = date.endOf("month").format("YYYY-MM-DD")
            getStatisticBy([start, end], (data) => {
                setExportMonthData({
                    name: `${month}`,
                    data: data,
                    loading: false
                })
            })
        }
    }

    function onQuarterChanged(date, quarter) {
        if (quarter) {
            setExportQuarterData({loading: true})
            let start = date.startOf('quarter').format("YYYY-MM-DD")
            let end = date.endOf('quarter').format("YYYY-MM-DD")
            getStatisticBy([start, end], (data) => setExportQuarterData({
                name: `${quarter}`,
                data: data,
                loading: false
            }))
        }
    }

    function onYearChanged(date, year) {
        if (year) {
            setExportYearData({loading: true})
            let start = date.startOf('year').format("YYYY-MM-DD")
            let end = date.endOf('year').format("YYYY-MM-DD")
            getStatisticBy([start, end], (data) => setExportYearData({
                name: `${year}`,
                data: data,
                loading: false
            }))
        }
    }

    return (
        <div className="admin-dashboard">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Space>
                    <RangePicker showTime={false} onChange={onRangeChanged}/>
                    <ExportCSV loading={exportRangeData.loading}
                               csvData={exportRangeData?.data}
                               fileName={exportRangeData?.name}/>
                </Space>
                <Space>
                    <DatePicker picker="month" onChange={onMonthChanged}/>
                    <ExportCSV loading={exportMonthData.loading}
                               csvData={exportMonthData?.data}
                               fileName={exportMonthData?.name}/>
                </Space>
                <Space>
                    <DatePicker onChange={onQuarterChanged} picker="quarter"/>
                    <ExportCSV loading={exportQuarterData.loading}
                               csvData={exportQuarterData?.data}
                               fileName={exportQuarterData?.name}/>
                </Space>
                <Space>
                    <DatePicker onChange={onYearChanged} picker="year"/>
                    <ExportCSV
                        loading={exportYearData.loading}
                        csvData={exportYearData?.data}
                        fileName={exportYearData?.name}/>
                </Space>
            </div>

            <br/>
            <br/>

            {
                monthStat ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Space align="center" size={50}>
                            <Button disabled={mIndex === months.length - 1} icon={<CaretLeftOutlined/>}
                                    onClick={() => {
                                        let index = mIndex + 1
                                        setMIndex(index)
                                        setMonthStat(months[index])
                                    }}/>
                            <div style={{fontSize: 22, fontWeight: "bold"}}>{monthStat.year_month}</div>
                            <Button disabled={mIndex === 0} icon={<CaretRightOutlined/>}
                                    onClick={() => {
                                        let index = mIndex - 1
                                        setMIndex(index)
                                        setMonthStat(months[index])
                                    }}/>
                        </Space>
                        <br/>
                        <br/>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                            <Card>
                                <Statistic
                                    style={{minWidth: 150}}
                                    title="Vận đơn (HCM)"
                                    value={monthStat.total_shipping_hcm}
                                    valueStyle={{color: 'green'}}
                                    prefix={<FileDoneOutlined/>}
                                />
                            </Card>
                            <Card>
                                <Statistic
                                    style={{minWidth: 200}}
                                    title="Lbs (HCM)"
                                    value={monthStat.total_weight_hcm}
                                    valueStyle={{color: '#f50'}}
                                    prefix={<GoldOutlined/>}
                                />
                            </Card>
                            <Card>
                                <Statistic
                                    style={{minWidth: 150}}
                                    title="Vận đơn (Tỉnh)"
                                    value={monthStat.total_shipping_other}
                                    valueStyle={{color: 'green'}}
                                    prefix={<FileDoneOutlined/>}
                                />
                            </Card>
                            <Card>
                                <Statistic
                                    style={{minWidth: 200}}
                                    title="Lbs (Tỉnh)"
                                    value={monthStat.total_weight_other}
                                    valueStyle={{color: '#f50'}}
                                    prefix={<GoldOutlined/>}
                                />
                            </Card>
                        </div>
                    </div>
                    : <></>
            }
            <br/>
            <br/>
            {
                yearStat ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                        <Space align="center" size={50}>
                            <Button disabled={yIndex === years.length - 1} icon={<CaretLeftOutlined/>}
                                    onClick={() => {
                                        let index = yIndex + 1
                                        setYIndex(index)
                                        setYearStat(years[index])
                                    }}/>
                            <div style={{fontSize: 22, fontWeight: "bold"}}>{yearStat.year}</div>
                            <Button disabled={yIndex === 0} icon={<CaretRightOutlined/>}
                                    onClick={() => {
                                        let index = yIndex - 1
                                        setYIndex(index)
                                        setYearStat(years[index])
                                    }}/>
                        </Space>
                        <br/>
                        <br/>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                            <Card>
                                <Statistic
                                    style={{minWidth: 150}}
                                    title="Vận đơn (HCM)"
                                    value={yearStat.total_shipping_hcm}
                                    valueStyle={{color: 'green'}}
                                    prefix={<FileDoneOutlined/>}
                                />
                            </Card>
                            <Card>
                                <Statistic
                                    style={{minWidth: 200}}
                                    title="Lbs (HCM)"
                                    value={yearStat.total_weight_hcm}
                                    valueStyle={{color: '#f50'}}
                                    prefix={<GoldOutlined/>}
                                />
                            </Card>
                            <Card>
                                <Statistic
                                    style={{minWidth: 150}}
                                    title="Vận đơn (Tỉnh)"
                                    value={yearStat.total_shipping_other}
                                    valueStyle={{color: 'green'}}
                                    prefix={<FileDoneOutlined/>}
                                />
                            </Card>
                            <Card>
                                <Statistic
                                    style={{minWidth: 200}}
                                    title="Lbs (Tỉnh)"
                                    value={yearStat.total_weight_other}
                                    valueStyle={{color: '#f50'}}
                                    prefix={<GoldOutlined/>}
                                />
                            </Card>
                        </div>
                    </div>
                    : <></>
            }
        </div>
    );
}
