import {Redirect, Route} from "react-router-dom";
import {Home, Login, Logout, Profile, Register} from "./components/views";
import ProtectedRoute from "./components/shared/protectedRoute";
import ChangePass from "./components/views/ChangePass";
import AdminDashboard from "./components/views/admin_dashboard/Dashboard";
import TrackingShippingTable from "./components/views/admin_dashboard/TrackingShippingTable";
import ScheduleTransportedTable from "./components/views/admin_dashboard/ScheduleTransportedTable";
import TransportHistoryTable from "./components/views/admin_dashboard/TransportHistoryTable";
import Branch from "./components/views/admin_dashboard/Branch";
import EditInfoWeb from "./components/views/admin_dashboard/EditInfo";
import BranchShippingTable from "./components/views/branch_dashboard/us/BranchShippingTable";
import UserShippingTable from "./components/views/user_dashboard/UserShippingTable";
import Consignee from "./components/views/user_dashboard/Consignee";
import VNBranchDashboard from "./components/views/branch_dashboard/vn/VNBranchDashBoard";

import React from "react";
import CustomerTable from "./components/views/admin_dashboard/CustomerTable";
import {CreatePost} from "./components/views/admin_dashboard/CreatePost";
import {Landing} from "./components/views/Landing";

export const commonRoutes = [
    <Route key={11} path='/landing' exact component={Landing}/>,
    <Route key={12} path='/home' exact component={Home}/>,
    <Route key={13} path='/register' exact component={Register}/>,
    <Route key={14} path='/login' exact component={Login}/>,
    <Route key={15} path='/admin' exact component={Login}/>,
    <ProtectedRoute key={16} path='/my-profile' exact component={Profile}/>,
    <ProtectedRoute key={17} path='/change-pass' exact component={ChangePass}/>,
    <ProtectedRoute key={18} path='/logout' exact component={Logout}/>,
    <Redirect key={19} from='/' exact to='/home'/>,
    <Redirect key={20} to='/home'/>,
]

export const routes = {
    1: [
        <Route key={1} path='/statistic' exact component={AdminDashboard}/>,
        <Route key={2} path='/shipping-queue' exact component={TrackingShippingTable}/>,
        <Route key={3} path='/set-transport' exact component={ScheduleTransportedTable}/>,
        <Route key={4} path='/transporting' exact component={TransportHistoryTable}/>,
        <Route key={5} path='/shipping-done' exact component={TrackingShippingTable}/>,
        <Route key={6} path='/customer' exact component={CustomerTable}/>,
        <Route key={21} path='/consignee' exact component={Consignee}/>,
        <Route key={7} path='/branch' exact component={Branch}/>,
        <Route key={8} path='/create-post' exact component={CreatePost}/>,
        <Route key={9} path='/info' exact component={EditInfoWeb}/>,
        <Route key={10} path='/shipping-pending' exact component={TrackingShippingTable}/>
    ],
    2: [
        <Route key={1} path='/shipping-queue' exact component={BranchShippingTable}/>,
        <Route key={2} path='/shipping' exact component={BranchShippingTable}/>,
    ],
    3: [
        <Route key={1} path='/shipping-queue' exact component={UserShippingTable}/>,
        <Route key={2} path='/shipping' exact component={UserShippingTable}/>,
        <Route key={3} path='/consignee' exact component={Consignee}/>,
    ],
    4: [
        <Route key={1} path='/shipping' exact component={VNBranchDashboard}/>,
        // <Route key={2} path='/shipping-pending' exact component={VNBranchDashboard}/>,
    ]
}