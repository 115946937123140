import React, {useState} from "react";
import {Modal} from 'antd';
import {useSelector} from "react-redux";
import {setActionCallback, userShippingTableColumns, VIEW_RECEIPT} from "../table_columns";
import BillForm from "../../shared/BillForm";
import {useLocation} from "react-router-dom";
import {getListShipping} from "../../../api";
import {MyList} from "../../LoadMoreTable";

const UserShippingTable = () => {
    const {user} = useSelector((state) => state.user);
    const {branchVN} = useSelector((state) => state.branch);

    const location = useLocation()

    setActionCallback(async (type, value, dataIndex) => {
        switch (type) {
            case VIEW_RECEIPT:
                value.sender = user
                await setCargoData(value)
                setVisible(true)
                break;

            // case ON_SEARCH_FIRED:
            //     setQueriedData(data.filter(item => item[dataIndex].toString().includes(value)))
            //     break;
            //
            // case ON_SEARCH_CLEARED:
            //     setQueriedData(null)
            //     break;
        }
    });


    //edit shipping approval
    const [visible, setVisible] = useState(false);
    const [cargoData, setCargoData] = useState(null)

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [noMoreData, setNoMoreData] = useState(false);

    function getShipping(callback) {
        let filter;
        if (location.pathname.includes("queue")) {
            filter = {status: 0, page: page}
        } else {
            filter = {page: page}
        }
        getListShipping(filter)
            .then((res) => {
                if (res.data.length === 0)
                    setNoMoreData(true)
                setData(prevState => ([...prevState, ...res.data]))
                setPage(page + 1)
                callback()
            })
            .catch((err) => {
                callback()
            });
    }


    return <div className="test">
        <Modal
            title={String(cargoData?.tracking_id).padStart(6, '0')}
            visible={visible}
            okText="Đóng"
            style={{minWidth: '800px', top: 10}}
            footer={null}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}>
            <BillForm data={cargoData} branchVN={branchVN}/>
        </Modal>

        <MyList
            rowKey='tracking_id'
            data={data}
            noMoreData={noMoreData}
            columns={userShippingTableColumns}
            fetcher={(callback) => getShipping(callback)}
        />
    </div>;
}

export default UserShippingTable;
