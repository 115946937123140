import React, {useEffect, useState} from "react";
import {Button, DatePicker, message, Modal, Space, Spin, Statistic, Table, Tag} from 'antd';
import {useSelector} from "react-redux";
import BillForm from "../../shared/BillForm";
import moment from "moment";
import {getShipping, getTransport, getTransportDates, removeTransport} from "../../../api";
import {ContainerOutlined, GoldFilled, InboxOutlined} from "@ant-design/icons";
import {Util} from "../../../utils";

const TransportHistoryTable = () => {

    const col_box_num = {
        title: 'Số thùng',
        dataIndex: 'box_num',
        align: 'center',
        sorter: (a, b) => a.box_num - b.box_num,
        width: 100,
    }

    const col_tracking_numbers = {
        title: 'Tracking No.(s)',
        dataIndex: ["box_num", "shipping"],
        width: 300,
        render: (_, record) => {
            return (
                <Space>
                    {record.tracking_ids.map(id => (
                        <Tag key={id}
                             color="geekblue"
                             style={{cursor: 'pointer'}}
                             onClick={async () => {
                                 let req = await getShipping(id);
                                 if (req.data) {
                                     await setCargoData(req.data)
                                     setVisible(true)
                                 }
                             }}>{String(id).padStart(6, '0')}
                        </Tag>
                    ))}
                </Space>
            )
        }
    }

    const col_ready_date = {
        title: 'Ngày đóng thùng',
        width: 150,
        align: 'center',
        dataIndex: 'ready_date',
        render: (value) => {
            return (<>{moment(value).utc().format("YYYY-MM-DD")}</>)
        },
    }

    const col_transport_date = {
        title: 'Ngày vận chuyển',
        width: 150,
        align: 'center',
        dataIndex: '',
        render: () => {
            return (<>{moment(history?.date).utc().format("YYYY-MM-DD")}</>)
        },
    }

    const col_box_weight = {
        title: 'Trọng lượng (Lbs)',
        dataIndex: 'weight',
        width: 100,
        align: 'right'
    }

    const col_transport_action = {
        title: 'Chức năng',
        key: 'edit',
        width: '120px',
        align: 'center',
        render: (_, record) => {
            return (
                <Button
                    size="small"
                    style={{width: 60}}
                    type="danger"
                    ghost
                    onClick={() => {
                        setDeleteBox(record)
                        showDeleteBoxModal(true)

                    }}>
                    Xóa
                </Button>
            )
        },
    }

    const buildColumn = () => {
        let col = [];
        col.push(col_box_num);
        col.push(col_box_weight);
        col.push(col_tracking_numbers);
        col.push(col_ready_date);
        col.push(col_transport_date);
        col.push(col_transport_action);
        return col;
    }

    const state = {
        bordered: true,
        showHeader: true,
        scroll: undefined,
        hasData: true,
        tableLayout: 'fixed',
    };

    const {branchVN} = useSelector((state) => state.branch);
    const [history, setHistory] = useState(null)
    const [loading, setLoading] = useState(false)
    const [transportedDates, setTransportedDates] = useState([])
    const [transportedBoxes, setTransportedBoxes] = useState([])
    const [totalWeight, setTotalWeight] = useState(0);
    const [deleteBox, setDeleteBox] = useState(null);
    const [deleteBoxVisible, showDeleteBoxModal] = useState(false);

    useEffect(() => {
        init()
    }, [])

    function init() {
        setLoading(true)
        getTransportDates()
            .then(res => {
                setTransportedDates(res.data)
                let lastHistory = res.data[0]
                if (lastHistory) {
                    setHistory(lastHistory)
                    // getTransportedBoxes(lastHistory._id) //do not init data
                } else {
                    // setTransportedBoxes([])
                }
                setLoading(false)
            })
            .catch(e => {
                message.error('unexpected error')
                setLoading(false)
            })
    }

    function getTransportedBoxes(history_id) {
        if (!history_id) {
            clearData();
            return;
        }
        setLoading(true)
        getTransport(1, history_id)
            .then(res => {
                setTransportedBoxes(res.data)
                setTotalWeight(Util.sum(res.data, 'weight'))
                setLoading(false)
            })
            .catch(err =>
                setLoading(false)
            )
    }

    const [visible, setVisible] = useState(false);
    const [cargoData, setCargoData] = useState(null)

    function onDateChange(value) {
        let find = transportedDates.find(e => e.date.includes(value.format('YYYY-MM-DD')))
        setHistory(find)
        getTransportedBoxes(find?._id)
    }

    function defaultDate() {
        const lastDate = transportedDates[0]?.date;
        return moment(lastDate)
    }

    function clearData() {
        setTransportedBoxes([])
        setTotalWeight(0)
    }

    return <Spin spinning={loading}>
        <div>
            {transportedDates.length === 0 ? <></> :
                <div style={{display: "flex", justifyContent: 'space-between', padding: '0 12px'}}>
                    {<Space size='small' direction='vertical'>
                        <div style={{lineHeight: '35px', fontWeight: "bold", fontSize: 16}}>Ngày vận chuyển</div>
                        <DatePicker
                            style={{width: 200, height: 35}}
                            // defaultValue={defaultDate()}
                            allowClear={false}
                            onChange={onDateChange}
                            dateRender={current => {
                                let isMatchDate = transportedDates.find(e => current.isSame(moment(e.date), 'day'))
                                const style = {};
                                if (isMatchDate) {
                                    style.border = '1px solid #1890ff';
                                    style.borderRadius = '50%';
                                }
                                return (
                                    <div className="ant-picker-cell-inner" style={style}>
                                        {current.date()}
                                    </div>
                                );
                            }}
                        />
                    </Space>}

                    <Space size='large'>
                        <Statistic prefix={<InboxOutlined/>} title="Tổng thùng"
                                   value={transportedBoxes.length}/>
                        <Statistic prefix={<ContainerOutlined/>} title="Tổng vận đơn"
                                   value={history?.tracking_ids.length}/>
                        <Statistic prefix={<GoldFilled/>} title="Trọng lượng"
                                   value={totalWeight.format(2)}/>
                    </Space>

                </div>
            }
            <br/>
            <Table
                size='small'
                className="history-transport-table"
                rowKey='box_num'
                {...state}
                pagination={false}
                columns={buildColumn()}
                loading={loading}
                dataSource={transportedBoxes ?? []}
                scroll={{y: '70vh'}}/>
        </div>

        <Modal
            title={String(cargoData?.tracking_id).padStart(6, '0')}
            centered
            visible={visible}
            okText="Đóng"
            width={800}
            footer={null}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}>
            <BillForm data={cargoData} branchVN={branchVN}/>
        </Modal>

        <Modal
            title="Xóa thùng"
            visible={deleteBoxVisible}
            onOk={() => {
                removeTransport({box: deleteBox})
                    .then(() => {
                        init()
                        setDeleteBox(null)
                        showDeleteBoxModal(false)
                        message.success("Xóa thành công")
                    })
            }}
            onCancel={() => {
                showDeleteBoxModal(false)
                setDeleteBox(null)
            }}
            okText="Xóa"
            cancelText="Hủy"
        >
            <p>{`Xóa thùng số ${deleteBox?.box_num} ra khỏi lịch vận chuyển.`}</p>
        </Modal>
    </Spin>;
}

export default TransportHistoryTable;