import http from "../services/httpService";

//auth
const postLogin = (user) => http.post("/api/auth/login", user);
const changePassword = (user) => http.put("/api/auth/change-pwd", user);
const postLogout = () => http.post("/api/auth/logout");
const postRegister = (user) => http.post("/api/auth/register", user);
const updateUser = (user) => http.put("/api/auth/update", user);
const getUser = () => http.get("/api/user");
const resetPwd = (data) => http.post("/api/auth/reset-pwd", data);
const removeAccount = (data) => http.post("/api/auth/delete-account", data);


const getStatistic = () => http.get("/api/statistic");
const getStatisticRange = (range) => http.post("/api/statistic/range", range);
const getTransportsData = () => http.get("/api/statistic/get-transports");

//shipping
const getShipping = (tracking_id) => http.get("/api/shipping", {params: {tracking_id: tracking_id}});
const getListShipping = (params) => http.get("/api/shipping/list", {
    params: {
        status: params?.status,
        tracking_ids: params?.tracking_ids,
        pending: params?.pending,
        page: params.page
    }
});
const postShipping = (data) => http.post("/api/shipping", data);
const putShipping = (data) => http.put("/api/shipping", data);
const doneShipping = (data) => http.put("/api/shipping/done", data);
const getTransport = (status, history_id) => http.get("/api/transport", {
    params: {
        status: status,
        history_id: history_id
    }
});

const getTransportHistory = () => http.get("/api/history");
const putSchedule = (data) => http.put("/api/transport", data);
const getTransportDates = () => http.get("/api/transport_dates");
const updateWeight = (data) => http.put("/api/update-weight", data);
const updateBoxes = (data) => http.put("/api/update-boxes", data);
const getEditableTransport = (history_ids, region) => http.get("/api/editable-transport", {
    params: {
        history_ids: history_ids,
        region: region
    }
});

const removeTransport = (box) => http.put("/api/remove-transport", box);

//branch
const getBranches = () => http.get("/api/branch");
const postBranch = (data) => http.post("/api/branch", data);
const updateBranch = (data) => http.put("/api/branch", data);
const putBranch = (data) => http.put("/api/branch", data);
const deleteBranch = (data) => http.post("/api/branch/delete", data);


//consignee
const getConsignees = (uid) => http.get("/api/consignee", {params: {_id: uid}});
const getConsigneesByUser = (uid) => http.get("/api/consignee", {params: {_id: uid}});
const postConsignee = (data) => http.post("/api/consignee", data);
const putConsignee = (data) => http.put("/api/consignee", data);
const deleteConsignee = (data) => http.delete("/api/consignee", data);

//customers
const getConsigneeByCustomer = (phone) => http.get("/api/user/consignee_by_customer", {params: {phone: phone}});
const getCustomers = () => http.get("/api/user/customers");
const getCustomerReceipts = (sender) => http.get("/api/shipping/receipts", {params: {sender: sender}});

const getInfo = () => http.get("/api/info");
const updateInfo = (data) => http.post("/api/info", data);

//post
const getPost = (page) => http.get("/api/post", {params: {page: page}});
const createPost = (data) => http.post("/api/post", data);
const updatePost = (data) => http.put("/api/post", data);
const deletePost = (id) => http.post("/api/post/delete", id);

export {
    postLogin,
    changePassword,
    postLogout,
    postRegister,
    updateUser,
    getUser,
    getStatistic,
    getStatisticRange,
    getTransportsData,
    resetPwd,
    removeAccount,
    
    getBranches,
    postBranch,
    updateBranch,
    putBranch,
    deleteBranch,

    getShipping,
    getListShipping,
    postShipping,
    putShipping,
    doneShipping,
    getTransport,
    getTransportHistory,
    putSchedule,
    getTransportDates,
    updateWeight,
    updateBoxes,
    getEditableTransport,
    removeTransport,

    getInfo,
    updateInfo,

    getConsignees,
    getConsigneesByUser,
    postConsignee,
    putConsignee,
    deleteConsignee,

    getConsigneeByCustomer,
    getCustomers,
    getCustomerReceipts,

    getPost,
    createPost,
    updatePost,
    deletePost
};
