import {getInfo, updateInfo} from "../../api/index";
import {editPost, setInfo} from "../actions/common";
import {push} from "connected-react-router";
export const attemptGetInfo = () => async (dispatch) => {
    let res = await getInfo();
    if (res.data)
        dispatch(setInfo(res.data[0]))
    else
        console.log('get info error');
}

export const attemptUpdateInfo = (data) => async (dispatch) => {
    let res = await updateInfo(data);
    if (res.data) {
        setInfo(res.data)
    }
    console.log(res.data)
}

export const setEditPost = (data) => (dispatch) => {
    dispatch(editPost(data))
    dispatch(push("/create-post"))
}

export const clearEditPost = () => (dispatch) => {
    dispatch(editPost(null))
    dispatch(push("/landing"))
}
