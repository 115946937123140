import {push} from "connected-react-router";
import {login, logout} from "../actions/user";

import {
    postRegister,
    postLogin,
    postLogout, changePassword, updateUser,
} from "../../api/index";
import {message} from "antd";
import {attemptGetUser} from "./user";

export const attemptLogin = (user) => async (dispatch) => {
    await postLogin(user)
        .then((res) => {
            dispatch(login(res.data.user));
            dispatch(push("/home"));
            message.success(res.data.message)
            return res.data;
        })
        .catch((err) => message.error(err.response.data.message));
};

export const attemptChangePass = (user) => async (dispatch) => {
    await changePassword(user)
        .then((res) => {
            dispatch(push("/home"));
            message.success('Đổi mật khẩu thành công.')
        })
        .catch((err) => {
            message.error(err.response.data.message)
        });
};

export const attemptUpdateUser = (user) => async (dispatch) => {
    await updateUser(user).then((res) => {
        dispatch(push("/home"))
        dispatch(attemptGetUser())
        message.success(res.data.message)
    }).catch((err) => message.error(err.response.data.message));
};


export const attemptLogout = () => async (dispatch) =>
    await postLogout()
        .then(() => {
            dispatch(logout());
            dispatch(push("/login"));
        })
        .catch(dispatch(push("/login")));

export const attemptRegister = (newUser) => async (dispatch) => {
    await postRegister(newUser).then(() => {
        dispatch(push("/login"))
    }).catch(dispatch(push("/register")));
};

