import {SET_INFO, GET_INFO, UPDATE_INFO, EDIT_POST} from "../actions/common";

const initialState = {
    editPost: null,
    info: null,
    loading: false,
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case GET_INFO:
            return {
                loading: false,
                info: action.info,
            };
        case UPDATE_INFO:
            return {
                loading: false,
            };
        case SET_INFO:
            return {
                info: action.info,
                loading: false
            };
        case EDIT_POST:
            return {
                editPost: action.post,
            };
        default:
            return state;
    }
}
