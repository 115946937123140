import Text from "antd/es/typography/Text";
import React from "react";
import "../style/bill_form.css"
import moment from "moment";
import logo from "../../logo2.png";
import qrcode from "../../qr_code.svg";
import {Util} from "../../utils";
import {Space} from "antd";

class BillForm extends React.Component {

    address(str) {

        let lines = Util.breakLine(str)
        return <div>
           <span className="underline">
               <b style={{width: '22%'}}>ADDRESS:</b> <div style={{fontSize: '11.5pt'}}>{lines[0]}</div>
            </span>
            <div className="line" style={{fontSize: '11.5pt'}}>{lines[1] ?? ' '}</div>
        </div>
    }
    
    getBoxes(params) {
        if(!params) return
        let boxes = Util.expandBoxes(params)
        return boxes.length > 1 ? "" : boxes[0]
    }

    render() {
        let shipping = this.props?.data
        let branchVN = this.props?.branchVN
        if (!shipping) return <></>
        return (
            <div key={shipping.tracking_id} className="bill-form">

                <div style={{display: "flex", justifyContent: 'space-between'}}>
                    <img className="logo"
                         style={{alignSelf: 'center', width: 110, height: "auto"}}
                         src={logo}
                         alt="logo"/>


                    <div className="col-info">
                        <Text>Address: 708 N CHOLLA, MESA, ARIZONA 85201</Text>
                        <br/>
                        <Space align='end' size={14}>
                            <img style={{
                                width: '54pt',
                                height: '54pt',
                            }} src={qrcode} alt="QR Code"/>
                            <div align='end'>
                                <Text>Email: <u>uscargoshipping@gmail.com</u></Text>
                                <br/>
                                <Text>Web: <u>www.uscargoshipping.net</u></Text>
                                <br/>
                                <Text>Cell phone: <b>(480) 878 8332</b></Text>
                            </div>

                        </Space>

                    </div>
                </div>

                <hr/>

                <div style={{display: "flex"}}>
                    <div style={{width: "50%"}}/>
                    <div style={{
                        textAlign: "right",
                        fontSize: '18pt',
                        fontWeight: "bold",
                        display: 'flex',
                        width: '50%',
                        lineHeight: '24pt',
                        flexDirection: 'column',

                    }} className="tracking_numer">

                        <div style={{display: 'flex'}}>
                            <div style={{fontSize: '12pt', lineHeight: '24pt'}}>TRACKING No.:</div>
                            <div style={{textAlign: "center", width: '65%'}}>
                                {shipping.branch.prefix} - &nbsp;&nbsp;&nbsp; {String(shipping.tracking_id).padStart(6, '0')}
                            </div>
                        </div>
                        <div/>
                    </div>
                </div>
                <div style={{display: "flex"}}>

                    <div style={{width: "50%"}}>
                        <div style={{
                            fontSize: '16pt',
                            fontWeight: "bold",
                            width: "100%",
                            textAlign: 'center'
                        }} className="title">SHIPPING RECEIPT
                        </div>
                    </div>
                    <table style={{width: "50%", textAlign: "center"}}>
                        <tbody>
                        <tr style={{height: 16, lineHeight: '16px', fontSize: '10pt'}}>
                            <th width="35%">TOTAL BOX(ES)</th>
                            <th width="65%">NUMBER OF BOX</th>
                        </tr>
                        <tr style={{height: 25, lineHeight: '25px', fontSize: '18pt'}}>
                            <th width="35%">{shipping.meta.box_count_2}</th>
                            <th width="65%">{this.getBoxes(shipping.meta.box_num)}</th>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <table width='100%'>
                    <tbody>
                    <tr style={{height: 32}}>
                        <th colSpan={2} className="col1">SENDER:</th>
                        <td colSpan={8} className="col2" style={{textAlign: 'center'}}><b
                            style={{fontSize: '14pt'}}>{shipping.sender.username.toUpperCase()}</b></td>
                        <th colSpan={2} className="col1">CONSIGNEE:</th>
                        <td colSpan={8} className="col2" style={{textAlign: 'center'}}><b style={{fontSize: '14pt'}}>
                            {shipping.meta.receive_at_office
                                ? this.props.branchVN.manager_name.toUpperCase()
                                : shipping.consignee.name.toUpperCase()}
                        </b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={10}>
                            {this.address(shipping.sender.address)}
                        </td>
                        <td colSpan={10}>
                            {this.address(shipping.meta.receive_at_office
                                ? this.props.branchVN.address.toUpperCase()
                                : shipping.consignee.address.toUpperCase())}
                        </td>
                    </tr>

                    <tr>
                        <th colSpan={2} className="col1">Cell phone:</th>
                        <td colSpan={8} className="col2"
                            style={{padding: "4px", fontSize: '12pt', textAlign: 'center'}}><b
                            style={{fontSize: '15pt'}}>{shipping.sender.phone}</b></td>
                        <th colSpan={2} className="col1">Cell phone:</th>
                        <td colSpan={8} className="col2"
                            style={{padding: "4px", fontSize: '12pt', textAlign: 'center'}}><b
                            style={{fontSize: '15pt'}}>{shipping.meta.receive_at_office
                            ? this.props.branchVN.phone.toUpperCase()
                            : shipping.consignee.phone.toUpperCase()}</b></td>
                    </tr>
                    <tr>
                        <td colSpan={20} style={{fontSize: '9pt'}}>
                            <div>I. Shippers certify that this cargo does not contain any unauthorized explosives,
                                incendiaries, or hazardous materials. Shippers consent to a search of this cargo.
                                Shippers am aware that this endorsements and original signature, along with other
                                shipping documents, will be retained on file for at least thirty days.
                            </div>
                            <div> II. Sender must purchase at least 5% of insurance for the total value if they
                                would
                                like to get a 100% reimbursement when the package gets lost. If senders are not
                                willing
                                to buy the insurance, the maximum reimbursement they will receive is 30% base on the
                                total value of your shipment.
                            </div>
                        </td>
                    </tr>
                    <tr className="highlighted-row" style={{textAlign: "center"}}>
                        <td className="col20" colSpan={4}>NUMBER OF BOX(ES):</td>
                        <td className="col20" colSpan={4}>TOTAL WEIGHT (LBS):</td>
                        <td colSpan={4}>UNIT PRICE:</td>
                        <td className="col20" colSpan={4}>TAX & FEE:</td>
                        <td className="col20" colSpan={4}>TOTAL AMOUNT:</td>
                    </tr>
                    <tr style={{height: 50, textAlign: "center", fontSize: '20pt'}}>
                        <td className="col20" colSpan={4}>{shipping.meta?.box_count_1}</td>
                        <td className="col20" colSpan={4}>{shipping.meta?.total_weight?.format(2)}</td>
                        <td colSpan={4}>{shipping.meta?.unit_price?.format(2)}</td>
                        <td className="col20" colSpan={4}>{shipping.meta?.tax?.format(2)}</td>
                        <td className="col20" colSpan={4}>{shipping.meta?.total_amount?.format(2)}</td>
                    </tr>
                    <tr className="highlighted-row" style={{textAlign: "center"}}>
                        <td colSpan={20}>DESCRIPTION OF GOODS</td>
                    </tr>
                    <tr>
                        <td colSpan={20} style={{padding: "8px", fontSize: '12pt'}}>
                            {shipping.meta.is_paid ? <div className="stamp">Đã thanh toán</div> : <></>}
                            <p style={{
                                whiteSpace: 'pre-line',
                                maxHeight: '120pt',
                                minHeight: '120pt',
                                lineHeight: '15pt',
                                orphans: 99,
                                columnCount: 3,
                                columns: '2in'
                            }}>{shipping.description}</p>
                            <p style={{margin:0, padding:0, textAlign: 'right'}}>{Util.toBoxesString(shipping.meta.box_num)}</p>
                        </td>
                    </tr>

                    <tr style={{height: '50pt'}}>
                        <th colSpan={8}>
                            <div style={{
                                whiteSpace: 'pre-line',
                                lineHeight: '16pt',
                                height: '80pt',
                                orphans: 99,
                                fontSize: '12pt',
                            }}>{shipping.meta.receive_at_office ? "Giao cho:" : "Note"}
                                {
                                    shipping.meta.receive_at_office
                                        ? <div style={{marginTop: 8, fontSize: '13pt'}}>
                                            <b>{shipping.consignee.name}</b>
                                            <div style={{fontWeight: "normal"}}>{shipping.consignee.phone}</div>
                                            <div style={{fontWeight: "normal"}}>{shipping.consignee.address}</div>
                                        </div>
                                        : <></>
                                }

                            </div>
                        </th>

                        <th colSpan={6}>
                            <br/>
                            <br/>
                            <hr style={{marginRight: '10%', width: '90%', float: 'left'}}/>
                            <div style={{fontSize: '11pt'}}>Shipper's
                                signature:&nbsp;&nbsp;{moment(shipping?.ready_date).local().format("YYYY-MM-DD")}</div>
                        </th>
                        <th colSpan={6}>
                            <br/>
                            <br/>
                            <hr style={{marginRight: '10%', width: '90%', float: 'left'}}/>
                            <div style={{fontSize: '11pt'}}>Sender's
                                signature:&nbsp;&nbsp;{moment(shipping?.send_date).local().format("YYYY-MM-DD")}</div>
                        </th>
                    </tr>
                    </tbody>
                </table>

                <hr/>
                <table width='100%'>
                    <tbody>
                    <tr style={{textAlign: "center", fontSize: "11pt"}}>
                        <td colSpan={1}>DOCUMENT'S SENT</td>
                        <td colSpan={2}>PLEASE PAY BY:</td>
                    </tr>
                    <tr style={{height: 80, fontSize: "12pt"}}>
                        <td colSpan={1} style={{width: '30%'}}>
                            <ul style={{listStyleType: "circle", marginBottom: 0}}>
                                <li>Shipping Receipt</li>
                                <li>Photo of Good</li>
                                <li>Shipping Receipt & Photo</li>
                            </ul>
                        </td>
                        <td colSpan={1} style={{width: '45%', verticalAlign: "top"}}>
                            <b><u>EMAIL:</u></b>
                            <br/>
                            <div style={{textAlign: "center", marginTop: 10, fontSize: '15pt'}}>
                                <b>USCARGOSHIPPING@GMAIL.COM</b>
                            </div>
                            <br/>
                        </td>
                        <td colSpan={1} style={{width: '25%', verticalAlign: "top"}}>
                            <b><u>CELL PHONE:</u></b>
                            <br/>
                            <div style={{textAlign: "center", marginTop: 10, fontSize: '15pt'}}><b>(480) 878 8332</b>
                            </div>
                            <br/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

// const mapStateToProps = state => {
//     return {
//         branchVN: state.branch.branches.find(b => b.type === "VN")
//     }
// }
//
// export default connect(mapStateToProps)(BillForm);
export default BillForm;