import { Form, message, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { postRegister } from "../../../api";
import RegisterForm from "../../shared/RegisterForm";

const AddCustomerModal = ({ data, visible, onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) form.setFieldsValue(data);
    else form.resetFields();
  });

  const addUser = (user) => {
    setLoading(true);
    postRegister(user)
      .then((res) => {
        setLoading(false)
        message.success(res.data.message)
        onSubmit(user)
      })
      .catch((err) => {
        message.error(err.response.data.message)
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        destroyOnClose={true}
        visible={visible}
        title={data ? `Cập nhật ${data.name}` : "Thêm Khách hàng"}
        okText={data ? `Cập nhật` : "Thêm"}
        cancelText="Hủy"
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              addUser(values)
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <RegisterForm data={null} form={form} />
      </Modal>
    </Spin>
  );
};

export default AddCustomerModal;
