import React, { useState, useRef } from "react";
import { Button, Col, Form, Input, Spin } from "antd";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../style/admin.css"
import { attemptUpdateInfo } from "../../../store/thunks/common";
import MaskedInput from 'antd-mask-input'
import SunEditor from 'suneditor-react';

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};

export default function EditInfoWeb() {
    const noticeLoginRef = useRef();
    const noticeRef = useRef();
    const { user } = useSelector((state) => state.user);
    const { info } = useSelector((state) => state.common);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onFinish = (values) => {
        setLoading(true)
        dispatch(attemptUpdateInfo(values))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    return !user && user.role !== 1 ? <Redirect to='/home' /> : (
        <Col md={16} lg={14} xl={12} className="form-wrapper">
            <Spin spinning={loading}>
                <Form
                    className="edit-info"
                    {...layout}
                    form={form}
                    name="edit-info"
                    onFinish={onFinish}
                    scrollToFirstError
                    initialValues={info}
                >

                    <Form.Item
                        name="address"
                        label="Địa chỉ"
                        rules={[
                            {
                                required: true,
                                message: 'Địa chỉ không được bỏ trống!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="Số điện thoại"
                        rules={[
                            {
                                required: true,
                                message: 'Số điện thoại không được bỏ trống!',
                            },
                        ]}>
                        <MaskedInput mask="(111) 111-1111" placeholder="(xxx) xxx-xxxx" />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="E-mail"
                        rules={[
                            {
                                type: 'email',
                                message: 'Email không hợp lệ',
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="fanpage"
                        label="Fanpage">
                        <Input />
                    </Form.Item>
                    <Form.Item name="notice_login"
                        label="Thông báo (Login)">
                        <SunEditor
                            showToolbar={true}
                            enableToolbar={true}
                            setDefaultStyle="font-family: calibri; font-size: 15px;"
                            ref={noticeLoginRef}
                            defaultValue={info?.notice_login}
                            setOptions={{
                                height: 150,
                                buttonList: [
                                    ['font', 'fontSize'],
                                    ['fontColor', 'hiliteColor'],

                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                ]
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="notice"
                        label="Thông báo">
                        <SunEditor
                            showToolbar={true}
                            enableToolbar={true}
                            setDefaultStyle="font-family: calibri; font-size: 15px;"
                            ref={noticeRef}
                            defaultValue={info?.notice}
                            setOptions={{
                                height: 150,
                                buttonList: [
                                    ['font', 'fontSize'],
                                    ['fontColor', 'hiliteColor'],

                                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'removeFormat'],
                                ]
                            }}
                        />
                    </Form.Item>
                    <br />
                    <Form.Item wrapperCol={24}>
                        <Button style={{ float: 'right' }} type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Col>
    );
}
