import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from '../../logo.png'; // Tell webpack this JS file uses this image
import Notices from "../shared/Notices";

export default function NavBar() {
    const { isAuth, user } = useSelector((state) => state.user);
    const { info } = useSelector((state) => state.common);
    const profileMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/my-profile">Hồ sơ</Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/change-pass">Đổi mật khẩu</Link>
            </Menu.Item>
            <Menu.Item key="logout">
                <Link to="/logout">Đăng xuất</Link>
            </Menu.Item>
        </Menu>
    );

    const userMenu = isAuth ? (
        <div style={{ alignSelf: 'center' }}>
            <Dropdown style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}
                overlay={profileMenu} placement="bottomCenter" arrow>
                <Button size='large' type="link" icon={<UserOutlined />}>{user.username}</Button>
            </Dropdown>
        </div>) : (<div />)

    return (
        <div style={{
            display: "flex",
            position: 'fixed',
            zIndex: 10,
            width: '100%',
            height: 50,
            backgroundColor: '#fff',
            boxShadow: '#e8872c 0px 0px 4px 2px',
            padding: '0 24px',
            justifyContent: 'space-between'
        }}>
            <Link style={{
                alignSelf: 'center',
            }} to="/landing">
                <img style={{ height: 35 }} src={logo} alt="logo" />
            </Link>
            <Notices notice={info?.notice}/>
            {userMenu}
        </div>
    );
}
