import {GET_TRANSPORT, GET_TRANSPORT_DATES, LOADING} from './types';
import {getTransport, getTransportDates, putSchedule} from "../../api";
import {message} from "antd";


export const loading = () => {
    return {
        type: LOADING,
        loading: true,
        error: null,
    }
};

// Get Posts
export const attemptGetTransport = (status, history_id) => async (dispatch) => {
    dispatch(loading());
    await getTransport(status, history_id)
        .then(res => {
                let shippingIds = new Set();
                res.data.forEach(e => {
                    e.tracking_ids.forEach(id => shippingIds.add(id))
                })
                dispatch({
                    type: GET_TRANSPORT,
                    payload: {
                        boxes: res.data,
                        tracking_ids: Array.from(shippingIds)
                    }
                })
            }
        )
        .catch(err =>
            dispatch({
                type: GET_TRANSPORT,
                payload: null
            })
        )
}

export const attemptAddTransportSchedule = (data) => async dispatch => {
    dispatch(loading());
    await putSchedule(data)
        .then((res) => {
            message.success('Đặt lịch thành công.')
        })
        .catch((err) => {
            message.error(err.response.data.message)
        });
}

export const attemptGetTransportDates = () => async dispatch => {
    dispatch(loading());
    await getTransportDates()
        .then( async (res) => {
            // dispatch(attemptGetTransport(1, res.data[res.data.length - 1].date))
            dispatch({
                type: GET_TRANSPORT_DATES,
                payload: res.data
            })
        })
        .catch((err) => {
            message.error(err.response.data.message)
        });
}
