import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import "../style/register.css";

import { attemptRegister } from "../../store/thunks/auth";

import { Button, Col, Form, Input, Row, Tooltip } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import MaskedInput from "antd-mask-input";

const formItemLayout = {
  labelCol: {
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const RegisterForm = ({data, form}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [serverError, setServerError] = useState("");

  const onFinish = (values) => {
    dispatch(attemptRegister(values)).catch((error) => {
      if (error.response) {
        setServerError(error.response.data);
      }
    });
  };

  const passWordFormItem = () => {
    return user.role === 1 ? (
      <div></div>
    ) : (
      <div>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            {
              required: true,
              message: "Vui lòng điền mật khảu!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Mật khẩu xác nhận"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Vui lòng điền mật khẩu xác nhận!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject("Mật khẩu không trùng khớp!");
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </div>
    );
  };

  const emailFormItem = () => {
    return user.role === 1 ? (
      <div></div>
    ) : (
      <div>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Email không hợp lệ",
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
    );
  };

  return (
    <Form
      className="register-form"
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item
        name="username"
        label="Họ & Tên"
        rules={[
          {
            required: true,
            message: "Xin nhập họ và tên của bạn!",
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Số điện thoại"
        validateStatus={serverError.phone ? "error" : ""}
        help={serverError.phone}
        rules={[
          {
            required: true,
            message: "Vui lòng nhập số điện thoại của bạn!",
          },
        ]}
      >
        <MaskedInput mask="(111) 111-1111" placeholder="(xxx) xxx-xxxx" />
      </Form.Item>

      {passWordFormItem()}

      <Form.Item
        name="address"
        label="Địa chỉ"
        rules={[
          {
            required: true,
            message: "Xin nhập địa chỉ của bạn!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      {emailFormItem()}

      {/* <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Đăng ký
        </Button>
      </Form.Item> */}
    </Form>
  );
};

export default RegisterForm;
