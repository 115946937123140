import {CLEAR_ERRORS} from "./types";

export const LOADING = "LOADING";
export const SET_INFO = "SET_INFO";
export const GET_INFO = "GET_INFO";
export const UPDATE_INFO = "UPDATE_INFO";
export const EDIT_POST = "EDIT_POST";

export const loading = (loading) => {
    return {
        type: LOADING,
        loading: loading
    }
};

export function setInfo(info) {
    return {
        type: SET_INFO,
        info,
    };
}

export function info(info) {
    return {
        type: GET_INFO,
        info
    };
}

export function editPost(post) {
    return {
        type: EDIT_POST,
        post
    };
}



export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};