import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, message, Modal, Space, Spin, Table,} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {PlusOutlined} from "@ant-design/icons";
import "../../style/branch.css"
import {deleteBranch, getBranches, postBranch, putBranch, resetPwd} from "../../../api";
import MaskedInput from 'antd-mask-input'

export default function Branch() {
    const {user} = useSelector((state) => state.user);

    const editColumn = {
        title: 'Chỉnh sửa',
        key: 'edit',
        width: '100px',
        align: 'center',
        render: (_, record) => (
            <Space direction="vertical">
                <Button
                    size="small"
                    type="primary"
                    style={{width: 120}}
                    onClick={() => {
                        setPopupVisible(true)
                        setPopupData(record)
                    }}>
                    Cập nhật
                </Button>
                <Button
                    size="small"
                    style={{width: 120}}
                    type="danger"
                    onClick={() => {
                        setPopupData(record)
                        showRsPwdModal(true)
                    }}>
                    Reset mật khẩu
                </Button>
            </Space>
        ),
    }
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 50,
            align: 'center',
        },
        {
            title: 'Tên viết tắt',
            dataIndex: "prefix",
            width: 80,
            align: 'center'
        },
        {
            title: 'Chi nhánh',
            dataIndex: "name",
            width: 100,
        },

        {
            title: 'Địa chỉ',
            dataIndex: 'address',
            width: 150,
        },
        {
            title: 'Quản lý',
            dataIndex: 'manager_name',
            width: 150,
        },
        {
            title: 'SĐT',
            dataIndex: 'phone',
            width: 100,
        },

    ];

    const state = {
        bordered: true,
        size: 'small',
        showHeader: true,
        scroll: undefined,
        hasData: true,
        tableLayout: 'fixed',
    };

    //edit popup
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [rsPwdVisible, showRsPwdModal] = useState(false);
    //
    const tableColumns = user?.role === 1 ? [...columns, editColumn] : columns;
    // const {branches, loading} = useSelector(state => state.branch)
    const dispatch = useDispatch();
    const [branches, setBranches] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        fetchBranch()
    }, [dispatch])

    function fetchBranch() {
        getBranches()
            .then(res => {
                let norData = res.data?.map((e, idx) => {
                    return {index: idx, ...e}
                })
                setBranches(norData)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    return <Col className="dashboard">

        <Space>
            <Button type="primary" icon={<PlusOutlined/>} onClick={() => {
                setPopupVisible(true)
                setPopupData({addNew: true, type: "US"})
            }}>Thêm chi nhánh</Button>
            <Button icon={<PlusOutlined/>} onClick={() => {
                setPopupVisible(true)
                setPopupData({addNew: true, type: "VN"})
            }}>Thêm chi nhánh VN</Button>
        </Space>

        <div>
            <UpdateBranchModal
                data={popupData}
                visible={popupVisible}
                onCancel={(update) => {
                    if (update) {
                        fetchBranch()
                    }
                    setPopupVisible(false);
                    setPopupData(null)
                }}
            />
            <Modal
                title="Đặt lại mật khẩu"
                visible={rsPwdVisible}
                onOk={() => {
                    resetPwd({phone: popupData.phone})
                        .then(res => {
                            message.success(res.data.message)
                            setPopupData(null)
                            showRsPwdModal(false)
                        })
                        .catch((err) => {
                            message.error(err.response.data.message)
                        })
                }}
                onCancel={() => {
                    showRsPwdModal(false)
                    setPopupData(null)
                }}
                okText="Đặt lại"
                cancelText="Hủy">
                <p>Xác nhận đặt lại mật khẩu cho chi nhánh <b>{popupData?.name}</b>.</p>
            </Modal>
            <br/>
            <Table
                className="branch-table"
                rowKey='index'
                {...state}
                pagination={false}
                columns={tableColumns}
                loading={loading}
                dataSource={branches ?? []}
                scroll={{y: '70vh'}}
            />
        </div>
    </Col>
}


const UpdateBranchModal = ({data, visible, onCancel}) => {

    const [form] = Form.useForm();
    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.setFieldsValue(data)
    })

    function addBranch(data) {
        setLoading(true)
        postBranch(data)
            .then(res => {
                message.success("Tạo chi nhánh thành công!")
                setLoading(false)
                onCancel(true)
            })
            .catch(err => {
                message.error(err.response.data.message)
                setLoading(false)
            })
    }

    function updateBranch(data) {
        setLoading(true)
        putBranch(data)
            .then(res => {
                message.success('Cập nhật thành công')
                setLoading(false)
                onCancel(true)
            })
            .catch(err => {
                message.error(err.response.data.message)
                setLoading(false)
            })
    }

    function removeBranch() {
        setLoading(true)
        deleteBranch({phone: data.phone})
            .then(res => {
                setLoading(false)
                message.success(res.data.message)
                form.resetFields()
                onCancel(true);
            })
            .catch(err => {
                setLoading(false)
                message.error(err.response.data.message)
            })
    }

    function createFooter() {
        let isNew = !data.name
        let footer = []
        footer.push(
            <Button key={1} onClick={() => {
                form.resetFields();
                onCancel();
            }}>Đóng</Button>
        )
        footer.push(
            <Button key={2} type="primary" onClick={() => {
                form.validateFields()
                    .then(async (values) => {
                        if (data?.addNew) {
                            addBranch({type: data.type, ...values})
                        } else {
                            let newData = {_id: data._id, oldName: data.name, oldPhone: data.phone, ...values}
                            updateBranch(newData)
                        }
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}>{isNew ? 'Thêm' : 'Cập nhật'}</Button>
        )
        if (!isNew) {
            footer.push(
                <Button style={{float: 'left'}} type="danger" key={3} onClick={() => {
                    removeBranch()
                }}>Xóa</Button>
            )
        }
        return footer
    }

    return !data ? <></> : (
        <Modal
            destroyOnClose={true}
            visible={visible}
            title={!data.name ? `Thêm chi nhánh ${data.type}` : `Chi nhánh ${data.name}`}
            onCancel={() => {
                form.resetFields()
                onCancel();
            }}
            footer={createFooter()}>
            <Spin spinning={loading}>
                <Form
                    {...layout}
                    form={form}
                    size="large"
                    name="form_in_modal">
                    <Form.Item
                        name="name"
                        label="Tên"
                        rules={[{required: true, message: 'Cần nhập tên chi nhánh'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="prefix"
                        label="Tên ngắn"
                        rules={[
                            {
                                required: true,
                                message: 'Tên ngắn không được bỏ trống!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Địa chỉ"
                        rules={[{required: true, message: 'Cần nhập địa chỉ'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="manager_name"
                        label="Quản lý"
                        rules={[{required: true, message: 'Cần nhập tên quản lý'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="SĐT"
                        rules={[{required: true, message: 'Cần số điện thoại'}]}>
                        <MaskedInput mask="(111) 111-1111"  placeholder="(xxx) xxx-xxxx"/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};