import {ADD_BRANCH, GET_BRANCH, GET_ERRORS, LOADING, UPDATE_BRANCH,} from './types';
import {deleteBranch, getBranches, postBranch, putBranch} from "../../api";
import {message} from "antd";
import {push} from "connected-react-router";


export const loading = () => {
    return {
        type: LOADING,
        loading: true,
        error: null,
    }
};

// Add Post
export const addBranch = data => async dispatch => {
    dispatch(loading());
    await postBranch(data)
        .then(res => {
            message.success("Tạo chi nhánh thành công!")
            dispatch(push("/branch"))
        })
        .catch(err => {
            dispatch({
                type: ADD_BRANCH,
                error: err.response.data.message
            })
            message.error(err.response.data.message)
        })
}

// Get Posts
export const getBranch = () => dispatch => {
    dispatch(loading());
    getBranches()
        .then(res =>
            dispatch({
                type: GET_BRANCH,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_BRANCH,
                payload: null
            })
        )
}


export const updateBranch = (data, closeModal) => async dispatch => {
    dispatch(loading());
    await putBranch(data)
        .then(res => {
            dispatch(getBranch())
            closeModal();
            message.success('Cập nhật thành công')
        })
        .catch(err => {
            dispatch({
                type: UPDATE_BRANCH,
                error: err.response.data.message
            })
            message.error(err.response.data.message)
        })
}


// Delete Post
export const removeBranch = data => dispatch => {
    dispatch(loading())
    deleteBranch(data)
        .then(res =>
            dispatch(getBranch())
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                error: err.response.data.message
            })
        )
};
