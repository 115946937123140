import React, {useEffect, useState} from "react";
import 'suneditor/dist/css/suneditor.min.css';
import {getPost} from "../../api";
import {Button, Col, Empty, Modal, Pagination, Row, Spin} from "antd";
import '../style/post.css';
import {Util} from "../../utils";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {setEditPost} from "../../store/thunks/common";

export const Landing = () => {
    const [page, setPage] = useState(1)
    const [posts, setPosts] = useState([])
    const [totalPosts, setTotalPosts] = useState(0)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [postData, setPostData] = useState(null)
    const dispatch = useDispatch()

    const {user} = useSelector((state) => state.user);

    useEffect(() => {
        getPostBy(1)
    }, [])

    function onPageChanged(page) {
        console.log(page)
        setPage(page)
        getPostBy(page)
    }

    function getPostBy(page) {
        setLoading(true)
        getPost(page)
            .then(res => {
                setPosts(res.data.posts)
                setTotalPosts(res.data.total_posts)
                setLoading(false)
            })
            .catch(() => setLoading(false))
    }

    function postsView() {

        return (
            <Row className="post-view">
                <Col xs={0} sm={0} md={3} xl={3} lg={3}/>
                <Col style={{width: '90%'}} xs={24} sm={24} md={18} xl={18} lg={18}>
                    {posts.map((e, index) => postItem(e, index))}
                </Col>
                <Col xs={0} sm={0} md={3} xl={3} lg={3}/>
            </Row>
        )
    }

    function postItem(data, index) {
        return (<div key={index}>
            <Row gutter={0} className="post-wrapper" onClick={() => {
                setPostData(data)
                setVisible(true)
            }}>
                <Col xs={8} sm={8} md={8} xl={8} lg={8}>
                    <img className="cover"
                         src={Util.getThumbnail(data.content)}
                         alt=" cover"/>
                </Col>
                <Col xs={15} sm={15} md={15} xl={15} lg={15} className="short-detail">
                    <div className="short_title">{data.title}</div>
                    <div className="time">{moment(data.create_at).format(" YYYY-MM-DD HH:mm")}</div>
                    <div className="short-desc">
                        <div dangerouslySetInnerHTML={{__html: data.content.replace(/<img[^>]*>/g,"")}}/>
                    </div>
                </Col>
            </Row>
        </div>)
    }

    const editButton = () => {
        return user?.role === 1
            ? (<Button key={1} style={{float: 'left'}} type="danger" onClick={async () => {
                dispatch(setEditPost(postData))
                setVisible(false)
            }}>Cập nhật</Button>)
            : <div key={1}/>
    }

    return (<Spin spinning={loading}>
        <div style={{margin: 8, display: " flex", alignItems: 'center', flexDirection: " column"}}>
            {posts.length === 0
                ? <Empty description={<div style={{color: 'gray'}}>Chưa có bài viết nào</div>}/>
                : postsView()}
            <br/>
            <br/>
            <Pagination size='small' defaultPageSize={5} current={page} total={totalPosts} onChange={onPageChanged}/>
        </div>
        <Modal
            centered
            visible={visible}
            footer={[
                editButton(),
                <Button key={3} onClick={() => {
                    setVisible(false)
                }}>Đóng</Button>
            ]}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={800}
        >
            <div className="title">{postData?.title}</div>
            <div className="time">{moment(postData?.create_at).format(" YYYY-MM-DD HH:mm")}</div>
            <div dangerouslySetInnerHTML={{__html: postData?.content}}/>
        </Modal>
    </Spin>)
};
