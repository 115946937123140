import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { attemptGetUser } from "./store/thunks/user";

import { NavBar, } from "./components/views";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import FooterBar from "./components/views/Footer";
import SideBar from "./components/views/SideBar";
import { commonRoutes, routes } from "./routes";
import { getBranch } from "./store/actions/branch_actions";
import Notices from "./components/shared/Notices";
import { attemptGetInfo } from "./store/thunks/common";


export default function App() {
    const { user } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { info } = useSelector((state) => state.common);
    useEffect(() => {
        dispatch(attemptGetUser())
            .then(() => {
                dispatch(getBranch())
                dispatch(attemptGetInfo())
                    .then(() => {
                        setLoading(false)
                    })
            })
            .catch(() => setLoading(false));
    }, [dispatch]);


    return loading ? (
        <p>Đang tải...</p>
    ) : (
        <Layout>
            {user ? <NavBar {...user} /> : <></>}
            <Layout>
                {user ? <SideBar {...user} /> : <></>}
                <Content style={{ marginTop: user ? '50px' : '22px' }} className="content">
                    {user ? <Notices className="mobile" notice={info?.notice} /> : <></>}
                    <Switch>
                        {!user ? '' : routes[user.role]}
                        {commonRoutes}
                    </Switch>
                </Content>
            </Layout>
            <FooterBar />
        </Layout>
    );


}
