import React, {useState, useEffect} from "react";
import {Col, message} from "antd";
import VNShippingTable from "./VNShippingTable";
import {getTransportDates} from "../../../../api";


export default function VNBranchDashboard() {

    const [transportedDates, setTransportedDates] = useState(null)

    useEffect(() => {
        if (!transportedDates)
            getTransportDates()
                .then(res => setTransportedDates(res.data))
                .catch(e => message.error(e.response.data.message))
    }, [transportedDates])

    return (
        <Col>
            {transportedDates ? <VNShippingTable dates={transportedDates}/> : <></>}
        </Col>
    );
}
