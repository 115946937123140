import {
    LOADING, ADD_CONSIGNEE, GET_CONSIGNEE
} from './types';
import {
    postConsignee,
    getConsignees,
    putConsignee,
    deleteConsignee
} from "../../api";
import {message} from "antd";

export const loading = () => {
    return {
        type: LOADING,
        loading: true,
        error: null,
    }
};

// Add Post
export const addConsignee = (data, closeModal) => async dispatch => {
    dispatch(loading());
    await postConsignee(data)
        .then(res => {
            message.success("Thêm người nhận thành công!")
            closeModal();
            dispatch(getConsignee())
        })
        .catch(err => {
            dispatch({
                type: ADD_CONSIGNEE,
                error: err.response.data.message
            })
            message.error(err.response.data.message)
        })
}

// Get Posts
export const getConsignee =  (uid) => async dispatch => {
    dispatch(loading());
    await getConsignees(uid)
        .then(res =>
            dispatch({
                type: GET_CONSIGNEE,
                payload: res.data
            })
        )
        .catch(err => {
                dispatch({
                    type: GET_CONSIGNEE,
                    payload: null
                })
                message.error(err.response.data.message)
            }
        )
}


export const updateConsignee = (data, closeModal) => async dispatch => {
    dispatch(loading());
    await putConsignee(data)
        .then(res => {
            closeModal();
            dispatch(getConsignee())
            message.success('Cập nhật thành công')
        })
        .catch(err => {
            message.error(err.response.data.message)
        })
}


// Delete Post
export const removeConsignee = data => dispatch => {
    dispatch(loading())
    deleteConsignee(data)
        .then(res =>
            dispatch(getConsignees())
        )
        .catch(err =>
            message.error(err.response.data.message)
        )
};
