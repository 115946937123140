import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Layout, Menu} from 'antd'
import {
    CalendarOutlined,
    ClockCircleOutlined,
    ClusterOutlined,
    FileDoneOutlined,
    HistoryOutlined,
    InfoCircleOutlined,
    PieChartOutlined,
    TeamOutlined,
    WarningOutlined
} from "@ant-design/icons";

const {Sider} = Layout

const menu = {
    1: [
        {key: '1', icon: <PieChartOutlined/>, label: 'Thống kê', path: '/statistic', role: "1"},
        {key: '2', icon: <ClockCircleOutlined />, label: 'Vận đơn (Chờ duyệt)', path: '/shipping-queue', role: "1"},
        {key: '5', icon: <FileDoneOutlined />, label: 'Vận đơn', path: '/shipping-done', role: "1"},
        {key: '3', icon: <CalendarOutlined />, label: 'Đặt lịch vận chuyển', path: '/set-transport', role: "1"},
        {key: '4', icon: <HistoryOutlined />, label: 'Vận chuyển', path: '/transporting', role: "1"},
        {key: '10', icon: <WarningOutlined />, label: 'Chưa thanh toán', path: '/shipping-pending', role: "1"},
        {key: '6', icon: <TeamOutlined />, label: 'Khách hàng', path: '/customer', role: "1"},
        {key: '21', icon: <TeamOutlined />, label: 'Người nhận', path: '/consignee', role: "1"},
        {key: '7', icon: <ClusterOutlined />, label: 'Chi nhánh', path: '/branch', role: "1"},
        {key: '8', icon: <ClusterOutlined />, label: 'Viết bài', path: '/create-post', role: "1"},
        {key: '9', icon: <InfoCircleOutlined />, label: 'Thông tin Web', path: '/info', role: "1"},
    ],
    2: [
        {key: '1', icon: <ClockCircleOutlined />, label: 'Chờ duyệt', path: '/shipping-queue', role: "2"},
        // {key: '2', icon: <FileDoneOutlined />, label: 'Vận đơn', path: '/shipping', role: "2"},
    ],
    3: [
        {key: '1', icon: <ClockCircleOutlined />, label: 'Chờ duyệt', path: '/shipping-queue', role: "3"},
        {key: '2', icon: <FileDoneOutlined />, label: 'Vận đơn', path: '/shipping', role: "3"},
        {key: '3', icon: <TeamOutlined />, label: 'Người nhận', path: '/consignee', role: "3"},
    ],
    4: [
        {key: '1', icon: <FileDoneOutlined />, label: 'Vận đơn', path: '/shipping', role: "4"},
        // {key: '2', icon: <WarningOutlined />, label: 'Tạm giữ', path: '/shipping-pending', role: "4"},
        // {key: '2', icon: <CarOutlined/>, label: 'Chưa giao xong', path: '/shipping-on-going', role: "4"},
    ]
}


const SideBar = (user) => {

    const location = useLocation()
    const history = useHistory()
    const [selectedKey, setSelectedKey] = useState(menu[user.role].find(_item => location.pathname === _item.path)?.key)

    const onClickMenu = (item) => {
        const clicked = menu[user.role].find(_item => _item.key === item.key)
        history.push(clicked.path)
    }

    useEffect(() => {
        setSelectedKey(menu[user.role].find(_item => location.pathname === _item.path)?.key)
    }, [location])

    return !user ? <></> : (
        <Sider breakpoint="lg"
               collapsedWidth="0"
               style={{zIndex: 3, marginTop: 64, maxHeight:300, background: 'white'}}>
            <Menu selectedKeys={[selectedKey]} mode='inline' onClick={onClickMenu}>
                {menu[user.role].map(item => (
                    <Menu.Item key={item.key} icon={item.icon}>{item.label}</Menu.Item>))}
            </Menu>
        </Sider>
    )
}

export default SideBar