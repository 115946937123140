import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import MaskedInput from 'antd-mask-input'

let searchInput;
export const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            {dataIndex === "phone"
                ? <MaskedInput
                    mask="(111) 111-1111"
                    placeholder="(xxx) xxx-xxxx"
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                : <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Tìm ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />}

            <Space>
                <Button onClick={() => {
                    handleReset(clearFilters)
                }} size="small" style={{ width: 90 }}>
                    Xóa
                </Button>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}>
                    Tìm
                </Button>
            </Space>

        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {

            setTimeout(() => searchInput?.select(), 100);
        }
    },
});

const handleSearch = (selectedKeys, confirm) => {
    confirm();
};

const handleReset = clearFilters => {
    clearFilters();
};