import React, { useEffect, useState } from "react";
import { Button, message, Modal, Space, Table } from "antd";
import "../../style/branch.css";
import {
  getCustomers,
  resetPwd,
  removeAccount,
  getConsigneesByUser,
} from "../../../api";
import { getColumnSearchProps } from "../../shared/search_menu";
import ConsigneeModal from "../user_dashboard/ConsigneeModal";
import { PlusOutlined } from "@ant-design/icons";
import AddCustomerModal from "./AddCustomerModal";

export default function CustomerTable() {
  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      width: 80,
      align: "center",
    },
    {
      title: "Tên",
      width: 120,
      dataIndex: "username",
      ...getColumnSearchProps("tên", "username"),
      render: (_, record) => {
        return <div>{record.username.toUpperCase()}</div>;
      },
    },
    {
      title: "SĐT",
      dataIndex: "phone",
      width: 120,
      align: "center",
      ...getColumnSearchProps("SĐT", "phone"),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      width: 240,
      align: "start",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
      align: "start",
    },
    {
      title: "Cập nhật",
      dataIndex: "action",
      width: 130,
      align: "center",
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Button
              size="small"
              style={{ width: 120 }}
              type="danger"
              ghost
              onClick={() => {
                setCustomer(record);
                showModal({ update: true, type: "update" });
              }}
            >
              Reset mật khẩu
            </Button>
          </Space>
        );
      },
    },
    {
      title: "Xóa tài khoản",
      dataIndex: "action",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <Space direction="vertical">
            <Button
              size="small"
              style={{ width: 120 }}
              type="danger"
              onClick={() => {
                setCustomer(record);
                showModal({ update: true, type: "delete" });
              }}
            >
              Xóa
            </Button>
          </Space>
        );
      },
    },
  ];

  const state = {
    bordered: true,
    size: "small",
    showHeader: true,
    scroll: undefined,
    hasData: true,
    tableLayout: "fixed",
  };

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [customer, setCustomer] = useState(null);
  const [modal, showModal] = useState({ type: "update", update: false, addCustomer: false });

  const [consignee, setConsignee] = useState(null);

  useEffect(() => {
    setLoading(true);
    refreshCustomers()
  }, []);

  function refreshCustomers(){
    getCustomers()
      .then((res) => {
        setCustomers(
          res.data.map((e, index) => {
            return {
              index: index + 1,
              ...e,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  function resetPassword(phone) {
    resetPwd({ phone: phone })
      .then((res) => {
        message.success(res.data.message);
        setCustomer(null);
        showModal({ update: false });
      })
      .catch((err) => {
        showModal({ update: false });
        setCustomer(null);
        message.error(err.response.data.message);
      });
  }

  function deleteAccount(phone) {
    removeAccount({ phone: phone })
      .then((res) => {
        message.success(res.data.message);
        setCustomer(null);
        setCustomers(customers.filter((e) => e.phone !== phone));
        showModal({ update: false });
      })
      .catch((err) => {
        setCustomer(null);
        showModal({ update: false });
        message.error(err.response.data.message);
      });
  }

  function createModal() {
    let isUpdate = modal.type === "update" ?? false;

    let title = isUpdate ? "Đặt lại mật khẩu" : "Xóa tài khoản";

    let content = isUpdate ? (
      <p>
        Xác nhận đặt lại mật khẩu cho <b>{customer?.username}</b>
      </p>
    ) : (
      <p>
        Xác nhận xóa tải khoản <b>{customer?.username}</b>
      </p>
    );

    return (
      <Modal
        title={title}
        visible={modal.update}
        onOk={() => {
          if (isUpdate) resetPassword(customer.phone);
          else deleteAccount(customer.phone);
        }}
        onCancel={() => {
          showModal({ update: false });
          setCustomer(null);
        }}
        okText="Đồng ý"
        cancelText="Hủy"
      >
        {content}
      </Modal>
    );
  }

  const [expKeys, setExpKeys] = React.useState([]);

  const expandedRowRender = (data) => {
    const columns = [
      {
        title: "STT",
        dataIndex: "index",
        key: "index",
        width: 82,
        align: "center",
        render: (_, record) => {
          return <b style={{ color: "purple" }}>{record.index}</b>;
        },
      },
      {
        title: "Tên",
        dataIndex: "name",
        key: "name",
        width: 220,
        render: (_, record) => {
          return (
            <div style={{ color: "black" }}>{record.name.toUpperCase()}</div>
          );
        },
      },
      {
        title: "SĐT",
        dataIndex: "phone",
        key: "phone",
        width: 150,
        render: (_, record) => {
          return <div style={{ color: "black" }}>{record.phone}</div>;
        },
      },
      {
        title: "Địa chỉ",
        dataIndex: "address",
        key: "address",
        width: "auto",
        render: (_, record) => {
          return <div style={{ color: "black" }}>{record.address}</div>;
        },
      },
      {
        title: "Update",
        dataIndex: "",
        key: "",
        align: "center",
        width: 150,
        render: (_, record) => {
          return (
            <Button
              size="small"
              onClick={() => {
                setConsignee(record);
              }}
            >
              Cập nhật
            </Button>
          );
        },
      },
    ];

    return (
      <Table
        className="consignee-table"
        bordered={true}
        showHeader={false}
        style={{ marginLeft: 42 }}
        columns={columns}
        dataSource={data.consignees}
        pagination={false}
        rowKey={(record) => record._id}
      />
    );
  };

  function getConsignees(uid, newExp) {
    let newCustomer = customers;
    let userIndex = newCustomer.findIndex((e) => e._id === uid);
    if (newCustomer[userIndex].consignees) {
      setExpKeys(newExp);
      return;
    }
    setLoading(true);
    getConsigneesByUser(uid)
      .then((res) => {
        let newUser = newCustomer[userIndex];
        newUser.consignees = res.data.map((e, i) => {
          return { ...e, index: i + 1 };
        });
        newCustomer[userIndex] = newUser;
        setCustomers(newCustomer);
        setExpKeys(newExp);
      })
      .finally(() => setLoading(false));
  }

  return (
    <div>
      <ConsigneeModal
        data={consignee}
        visible={consignee}
        onUpdated={(data) => {
          let newCustomers = [...customers];
          let customerIndex = newCustomers.findIndex(
            (e) => e._id === data.sender
          );
          let newCustomer = newCustomers[customerIndex];
          let consigneeIndex = newCustomer.consignees.findIndex(
            (e) => e._id === data._id
          );
          newCustomer.consignees[consigneeIndex] = data;
          newCustomers[customerIndex] = newCustomer;
          setCustomers(newCustomers);
          setConsignee(null);
        }}
        onCancel={() => {
          setConsignee(null);
        }}
      />
      <AddCustomerModal
        data={null}
        visible={modal.addCustomer}
        onSubmit={(data) => {
          showModal({addCustomer: false})
          refreshCustomers()
        }}
        onCancel={() => {
          showModal({addCustomer: false})
        }}
      />
      <Button
        type="primary"
        className="add-customer"
        icon={<PlusOutlined />}
        style={{ margin: "8px 0" }}
        onClick={async () => {
          showModal({addCustomer: true})
        }}
      >
        Thêm khách hàng
      </Button>
      <Table
        rowKey="index"
        {...state}
        pagination={false}
        columns={columns}
        loading={loading}
        dataSource={customers}
        expandedRowKeys={expKeys}
        onExpand={(b, r) => {
          const newExp = b
            ? [...expKeys, r.index]
            : expKeys.filter((i) => i !== r.index);
          if (b) getConsignees(r._id, newExp);
          else setExpKeys(newExp);
        }}
        expandedRowRender={(record) => expandedRowRender(record)}
        scroll={{ y: "85vh" }}
      />
      {createModal()}
    </div>
  );
}
