import React from "react";
import {attemptChangePass} from "../../store/thunks/auth";
import {useDispatch} from "react-redux";

import {Button, Col, Form, Input} from 'antd';
import "../style/change_pwd.css"

const ChangePass = () => {

    const layout = {
        labelCol: {span: 10},
        wrapperCol: {span: 14},
    };

    const dispatch = useDispatch();

    const onFinish = (values) => {
        dispatch(attemptChangePass(values))
    };

    return (
        <Col md={16} lg={14} xl={8} className="from-wrapper">
            <Form
                {...layout}
                name="change-pass"
                className="change-pass-form"
                onFinish={onFinish}>

                <Form.Item
                    name="password"
                    label="Mật khẩu cũ"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền mật khảu cũ!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="new_password"
                    label="Mật khẩu mới"
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền mật khảu mới!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') !== value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Mật khẩu mới phải khác với mật khẩu cũ!');
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Mật khẩu xác nhận"
                    dependencies={['new_password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng điền mật khẩu xác nhận!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new_password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject('Mật khẩu xác nhận không trùng khớp!');
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>

                <Form.Item wrapperCol={24}>
                    <Button style={{float: 'right'}} type="primary" htmlType="submit" className="apply-button">
                        Đồng ý
                    </Button>
                </Form.Item>
            </Form>
        </Col>
    );
};
export default ChangePass;

