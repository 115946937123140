import { Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  addConsignee,
  updateConsignee,
} from "../../../store/actions/consignee_actions";
import MaskedInput from "antd-mask-input";

const ConsigneeModal = ({ customer, data, visible, onCreate, onCancel, onUpdated }) => {

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) form.setFieldsValue(data);
    else form.resetFields();
  });

  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      title={data ? `Cập nhật ${data.name}` : "Thêm người nhận"}
      okText={data ? `Cập nhật` : "Thêm"}
      cancelText="Hủy"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            if (data) {
              let newData = { _id: data._id, oldPhone: data.phone, ...values };
              await dispatch(updateConsignee(newData, onCreate));
              if (onUpdated) onUpdated({ ...newData, sender: data.sender })
            } else {
              let newData = null
              if(customer){
                newData = {...values, customerId: customer._id}
              }else{
                newData = values
              }
              await dispatch(addConsignee(newData, onCreate));
            }
            form.setFieldsValue(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {/*<Spin spinning={false}>*/}
      <Form {...layout} form={form} name="form_in_modal">
        <Form.Item
          name="name"
          label="Tên"
          rules={[{ required: true, message: "Cần nhập tên người nhận" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="SĐT"
          rules={[{ required: true, message: "Cần nhập số điện thoại" }]}
        >
          <MaskedInput mask="(111) 111-1111" placeholder="(xxx) xxx-xxxx" />
        </Form.Item>
        <Form.Item
          name="address"
          label="Địa chỉ"
          rules={[{ required: true, message: "Cần nhập địa chỉ" }]}
        >
          <Input />
        </Form.Item>
      </Form>
      {/*</Spin>*/}
    </Modal>
  );
};

export default ConsigneeModal;
