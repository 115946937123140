import React from "react";
import {Button, Col, Image, Input, Row, Space, Table, Tag} from "antd";
import moment from "moment";
import {CarryOutOutlined, CheckCircleOutlined, ClockCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {Util} from "../../utils";

export const UPDATE_RECEIPT = 'UPDATE_RECEIPT';
export const EDIT_BOX = 'EDIT_BOX';
export const VIEW_RECEIPT = 'VIEW_RECEIPT';
export const SHOW_DELIVERY_CONFIRM = 'SHOW_DELIVERY_CONFIRM';
export const ON_SEARCH_FIRED = 'ON_SEARCH_FIRED';
export const ON_SEARCH_CLEARED = 'ON_SEARCH_CLEARED';
export let clearSearchFilter

let actionCallback;

function setActionCallback(action) {
    actionCallback = action;
}

let searchInput;

const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
        <div style={{padding: 8}}>
            <Input
                ref={node => {
                    searchInput = node;
                }}
                placeholder={`Tìm ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{width: 188, marginBottom: 8, display: 'block'}}
            />
            <Space>
                <Button onClick={() => {
                    handleReset(clearFilters)
                }} size="small" style={{width: 90}}>
                    Xóa
                </Button>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex, clearFilters)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{width: 90}}>
                    Tìm
                </Button>
            </Space>

        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    // onFilter: (value, record) =>
    //     record[dataIndex]
    //         ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //         : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {

            setTimeout(() => searchInput.select(), 100);
        }
    },
    // render: text =>
    //     this.state.searchedColumn === dataIndex ? (
    //         <Highlighter
    //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //             searchWords={[this.state.searchText]}
    //             autoEscape
    //             textToHighlight={text ? text.toString() : ''}
    //         />
    //     ) : (
    //         text
    //     ),
});

const handleSearch = (selectedKeys, confirm, dataIndex, clearFilter) => {
    confirm();
    clearSearchFilter = clearFilter
    actionCallback(ON_SEARCH_FIRED, Util.ltrim('0', selectedKeys[0]), dataIndex)
};

const handleReset = clearFilters => {
    clearFilters();
    actionCallback(ON_SEARCH_CLEARED)
};


const col_tracking_number = {
    title: 'Tracking No.',
    dataIndex: 'tracking_id',
    align: 'center',
    sorter: (a, b) => a.tracking_id - b.tracking_id,
    width: 120,
    ...getColumnSearchProps('Tracking No.', 'tracking_id'),
    render: (value, record) => <Text>{record.branch.prefix} - {String(value).padStart(6, '0')}</Text>
}

const col_sender = {
    title: 'Người gửi',
    dataIndex: ["sender"],
    width: 200,
    render: (sender) => {
        return (
            <Col>
                <Text strong>{sender?.username}</Text>
                <br/>
                <Text>{sender?.phone}</Text>
                <br/>
                <Text>{sender?.address}</Text>
            </Col>
        )
    }
}

const col_consignee = {
    title: 'Người nhận',
    dataIndex: ["consignee"],
    width: 200,
    render: (consignee) => {
        return (
            <Col>
                <Text strong>{consignee.name}</Text>
                <br/>
                <Text>{consignee.phone}</Text>
                <br/>
                <Text>{consignee.address}</Text>
            </Col>
        )
    }
}

const col_branch = {
    title: 'Chi nhánh',
    dataIndex: 'branch',
    width: 200,
    render: (branch) => {
        return (
            <Col>
                <Text strong>{branch?.name}</Text>
                <br/>
                <Text>{branch?.manager_name}</Text>
                <br/>
                <Text>{branch?.phone}</Text>
            </Col>
        )
    }
}

const col_create_date = {
    title: 'Ngày tạo',
    dataIndex: 'send_date',
    align: 'center',
    width: 120,
    sorter: (a, b) => moment(a.send_date) - moment(b.send_date),
    render: (text) => <Text>{moment(text).local().format("YYYY-MM-DD HH:mm")}</Text>
}


const col_received_date = {
    title: 'Ngày nhận',
    dataIndex: 'done_date',
    align: 'center',
    width: 120,
    render: (text) => !text ? <></> : <Text>{moment(text).local().format("YYYY-MM-DD HH:mm")}</Text>
}

function statusTags(status, record) {
    if (status === 0) { //waiting
        return (<Tag icon={<ClockCircleOutlined/>} color="default"> Đang duyệt</Tag>)
    }
    if (status === 1) { //ready
        return (<Tag style={{width: 120}} icon={<CarryOutOutlined/>} color="warning"> Sẵn sàng</Tag>)
    }

    if(status === 2){ //on going
        let history = record.history;
            
        let transportCount = 0;
        let deliveryCount = 0;

        history.forEach(e => {
            if (e.type === 'transport') {
                transportCount += e.boxes.length;
            }
            if (e.type === 'delivery') {
                deliveryCount += e.boxes.length;
            }
        })

        return (<Space direction="vertical">
            <Tag style={{width: 120}} color="magenta">
                <Row>
                    <Col sm={8}>
                        <b>{transportCount + "/" + record.meta.box_count_2}</b>
                    </Col>
                    <Col style={{textAlign: 'start'}} sm={16}> Vận chuyển </Col>
                </Row>
            </Tag>
            <Tag style={{width: 120}} color="processing">
                <Row>
                    <Col sm={8}>
                        <b>{deliveryCount + "/" + record.meta.box_count_2}</b>
                    </Col>
                    <Col style={{textAlign: 'start'}} sm={16}> Đã giao</Col>
                </Row>
            </Tag>
            {record.meta.is_paid ? <></> : <Tag style={{width: 120}} color="orange">Chưa thanh toán</Tag>}
        </Space>)
    }

    return (<Tag icon={<CheckCircleOutlined/>} style={{width: 120}} color="success">Đã giao</Tag>)
}

const col_status = {
    title: 'Trạng thái',
    dataIndex: 'status',
    width: 160,
    align: 'center',
    render: (status, record) => statusTags(status, record),
}

const col_admin_status = {
    title: 'Trạng thái',
    dataIndex: 'status',
    width: 150,
    align: 'center',
    filters: [
        {
            text: 'Đang vận chuyển',
            value: 2,
        },
        {
            text: 'Đã giao',
            value: 3,
        },
        {
            text: 'Chưa thanh toán',
            value: false,
        },
    ],
    filterMultiple: false,
    onFilter: (value, record) => Number.isInteger(value) ? record.status === value : record.meta.is_paid === value,
    render: (status, record) => statusTags(status, record),
}

const col_branch_us_action = {
    title: 'Chức năng',
    key: 'edit',
    width: '120px',
    align: 'center',
    render: (_, record) => {
        const disable = record.status === -1 || record.status > 1;
        return (
            <Space direction="vertical" onClick={(e) => e.stopPropagation()}>
                <Button
                    size="small"
                    style={{width: 100}}
                    type="primary"
                    ghost
                    disabled={disable}
                    onClick={() => {
                        actionCallback(UPDATE_RECEIPT, record)
                    }}>
                    Cập nhật
                </Button>
                <Button
                    size="small"
                    style={{width: 100}}
                    type="danger"
                    onClick={() => {
                        actionCallback(VIEW_RECEIPT, record)
                    }}
                    ghost>
                    Biên nhận
                </Button>
            </Space>

        )
    },
}

const col_admin_action = {
    title: 'Chức năng',
    key: 'edit',
    width: '120px',
    align: 'center',
    render: (_, record) => {
        return (
            <Col onClick={(e) => e.stopPropagation()}>
                <Button
                    disabled={record.status === 3} //if done
                    size="small"
                    style={{width: 100}}
                    type="danger"
                    ghost
                    onClick={() => {
                        actionCallback(UPDATE_RECEIPT, record)
                    }}>
                    Cập nhật
                </Button>

                <Button
                    size="small"
                    style={{width: 100, marginTop: 10}}
                    type="primary"

                    onClick={() => {
                        actionCallback(VIEW_RECEIPT, record)
                    }}
                    ghost>
                    Biên nhận
                </Button>
            </Col>

        )
    },
}

const col_admin_other_action = {
    title: 'Khác',
    key: 'edit',
    align: 'center',
    width: '120px',
    render: (_, record) => {
        return (<Col onClick={(e) => e.stopPropagation()}>
            <Button
                disabled={record.status === 3} //if done
                style={{width: '100px'}}
                type="link"
                onClick={() => {
                    actionCallback(EDIT_BOX, record)
                }}>
                Cập nhật thùng
            </Button>
        </Col>)
    },
}

const col_branch_vn_action = {
    title: 'Chức năng',
    key: 'edit',
    align: 'center',
    width: '100px',
    render: (_, record) => {
        return (<Col onClick={(e) => e.stopPropagation()}>
            <Button
                style={{width: '100px'}}
                type="dashed" danger
                disabled={record.status < 2 && record.status > 4}
                onClick={() => {
                    actionCallback(SHOW_DELIVERY_CONFIRM, record)
                }}>
                Đã giao
            </Button>
        </Col>)
    },
}

const col_user_action = {
    title: 'Biên nhận',
    key: 'edit',
    width: '120px',
    align: 'center',
    render: (_, record) => {
        const disable = record.status === -1 || record.status > 1;
        return (
            <Col onClick={(e) => e.stopPropagation()}>
                <Button
                    style={{width: 100, marginTop: 10}}
                    type="primary"
                    size="small"
                    onClick={async () => {
                        actionCallback(VIEW_RECEIPT, record)
                    }}
                    ghost>
                    Xem
                </Button>
            </Col>

        )
    },
}

const adminShippingTableColumns1 = [
    col_tracking_number,
    col_sender,
    col_consignee,
    col_branch,
    // col_create_date,
    // col_received_date,
    col_admin_status,
    col_admin_action,
]


const adminShippingTableColumns2 = [
    ...adminShippingTableColumns1,
    col_admin_other_action,
]

const branchUSShippingTableColumns = [
    col_tracking_number,
    col_sender,
    col_consignee,
    col_create_date,
    col_status,
    col_branch_us_action,
]

const branchVNShippingTableColumns = [
    col_tracking_number,
    // col_sender,
    col_consignee,
    col_status,
    col_branch_vn_action,
]

const userShippingTableColumns = [
    col_tracking_number,
    col_consignee,
    col_branch,
    col_create_date,
    col_received_date,
    col_status,
    col_user_action,
]

const expandedRowRender = (record) => {
    const columns = [
        {dataIndex: "name", key: 'name', width: '10%', align: 'left'},
        {
            title: 'Chi tiết',
            dataIndex: "detail",
            key: 'detail',
            width: '10%',
            align: 'left',
            render: (value, row, index) => {
                return {
                    children: value,
                    props: {colSpan: index === 6 ? 4 : 1},
                };
            }
        },
        {
            title: 'Mô tả', dataIndex: "description", key: 'description', render: (value, row, index) => {
                return {
                    children: <p style={{whiteSpace: 'pre-line', columnCount: 3, orphans: 10}}>{value}</p>,
                    props: {rowSpan: index === 0 ? 6 : 0, width: '40%'},
                };
            }
        },
        {
            title: 'Ghi chú', dataIndex: "message", key: 'message', render: (value, row, index) => {
                return {
                    children: value,
                    props: {rowSpan: index === 0 ? 6 : 0, width: '15%'},
                };
            }
        },
        {
            title: 'Ảnh', dataIndex: "photos", key: 'photos', render: (value, row, index) => {
                return !value ? <></> : {
                    children: <>
                        <Image.PreviewGroup>
                            {value?.map((e, index) => <Image
                                key={index}
                                width={150}
                                src={e}
                            />)}
                        </Image.PreviewGroup>
                    </>,
                    props: {rowSpan: index === 0 ? 6 : 0, width: '25%'},
                };
            }
        },
    ];

    const childData = [
        {
            name: "Số lượng thùng",
            detail: record.meta.box_count_1,
            description: record.description,
            photos: record.photos,
            message: record.meta.message,
        },
        {
            name: <div>Số lượng thùng<br/>(vận chuyển)</div>,
            detail: record.meta.box_count_2,
        },
        {
            name: "Số thùng",
            detail: record.meta.box_num ?? "Chưa có",
        },
        {
            name: "Trọng lượng (lbs)",
            detail: record.meta.total_weight ?? 0,
        },
        {
            name: "Đơn giá ($/lbs)",
            detail: record.meta.unit_price ?? 0,
        },
        {
            name: "Thuế ($)",
            detail: record.meta.tax ?? 0,
        },
        {
            name: "Thành tiền ($)",
            detail: record.meta.total_amount ?? 0,
        }
    ]
    return <Table className="table-detail"
                  rowClassName={(record, index) => index === 6 ? 'row-total-amount' : 'row-detail'} rowKey='name'
                  size='small' bordered={true} columns={columns} dataSource={childData}
                  pagination={false}/>;
};

export {
    adminShippingTableColumns1,
    adminShippingTableColumns2,
    branchUSShippingTableColumns,
    branchVNShippingTableColumns,
    userShippingTableColumns,
    expandedRowRender,
    setActionCallback,
}