import React, {useEffect, useState} from "react";
import {Table} from "antd";
import ShippingDetailModal from "./shared/ShippingDetail";

let lastScrollX = 0

const useInfiniteScroll = (callback) => {
    const [loading, setLoading] = useState(false);

    let node;
    useEffect(() => {
        node = document.querySelector(".table .ant-table-body")
        node.addEventListener('scroll', handleScroll);
        return () => node.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!loading) return;
        callback();
    }, [loading]);

    function handleScroll() {
        if (isHorizontalScroll()) return;
        let remaining = node.scrollHeight - (node.scrollTop + node.clientHeight)
        if (remaining >= 1 || loading) return;
        setLoading(true);
    }

    function isHorizontalScroll() {
        let scrollLeft = node.scrollLeft
        if (lastScrollX !== scrollLeft) {
            lastScrollX = scrollLeft
            return true
        }
        return false
    }

    return [loading, setLoading];
};


export const MyList = ({fetcher, columns, data, noMoreData, expandable}) => {
    const [loading, setLoading] = useInfiniteScroll(onLoadMore);
    const [detailVisible, setDetailVisible] = useState(false)
    const [shippingData, setShippingData] = useState(null)

    useEffect(() => {
        if (data.length === 0) onLoadMore()
    }, [data])


    function onLoadMore() {
        if (noMoreData) {
            setLoading(false)
            return
        }
        fetcher(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Table
                bordered={true}
                showHeader={true}
                size='small'
                rowKey='tracking_id'
                loading={loading}
                className="table"
                columns={columns}
                dataSource={data}
                pagination={false}
                onRow={(r) => ({
                    onClick: () => {
                        setShippingData(r)
                        setDetailVisible(true)
                    }
                })}
                scroll={{y: '80vh', scrollToFirstRowOnChange: false}}
            />
            <ShippingDetailModal visible={detailVisible}
                                 data={shippingData}
                                 onClose={() => {
                                     setDetailVisible(false)
                                     setShippingData(null)
                                 }}/>
        </>
    );
};
