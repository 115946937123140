import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";

import user from "./user";
import shipping from "./shipping";
import common from "./common";
import branch from "./branch_reducer";
import consignee from "./consignee_reducer";
import error from "./error_reducer";
import transport from "./transport_reducer";

export default (history) =>
    combineReducers({
        router: connectRouter(history), // Connects react router to redux
        user,
        shipping,
        common,
        consignee,
        branch,
        transport,
        error,
    });
