import {
    GET_BRANCH,
    ADD_BRANCH,
    UPDATE_BRANCH,
    REMOVE_BRANCH, LOADING
} from '../actions/types';

const initialState = {
    branches: [],
    branchVN: null,
    loading: false,
    error: '',
    msg: ''
};

export default function branch(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                msg: ''
            };
        case GET_BRANCH:
            return {
                ...state,
                branches: action.payload,
                branchVN: action.payload.find(b => b.type === "VN"),
                loading: false,
                error: action.error,
                msg: action.message
            };
        case ADD_BRANCH:
            return {
                ...state,
                loading: false,
                error: action.error,
                msg: action.message
            }
        case UPDATE_BRANCH:
            return {
                ...state,
                loading: false,
                error: action.error,
                msg: action.message
            };
        case REMOVE_BRANCH:
            return {
                ...state,
                loading: false,
                error: action.error,
                msg: action.message
            }
        default:
            return state;
    }
}