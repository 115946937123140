import React, { useEffect } from "react";
import { Col } from "antd";
import Text from "antd/es/typography/Text";
import { useSelector } from "react-redux";

export default function FooterBar() {
  const { info } = useSelector((state) => state.common);

  return (
    <div>
      <div className="footer-bar">
        <Col>
          <Text>{`Address: ${info?.address}`}</Text>
          <br />
          <Text>{`Cell phone: ${info?.phone}`}</Text>
          <br />
          <Text>
            Fanpage:{" "}
            <a
              href={`https://www.facebook.com/${info?.fanpage}`}
              target="_blank"
              rel="noreferrer"
            >
              {info?.fanpage}
            </a>
          </Text>
          <br />
          <Text>
            Email:{" "}
            <a href={"mailto:" + "uscargoshipping@gmail.com"}>{info?.email}</a>
          </Text>
        </Col>
      </div>
    </div>
  );
}
