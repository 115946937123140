import {
    GET_CONSIGNEE,
    ADD_CONSIGNEE,
    UPDATE_CONSIGNEE,
    REMOVE_CONSIGNEE,
    LOADING,
} from '../actions/types';

const initialState = {
    consignees: [],
    loading: false,
    error: '',
    msg: ''
};

export default function consignee(state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                msg: ''
            };
        case GET_CONSIGNEE:
            return {
                ...state,
                consignees: action.payload,
                loading: false,
                error: action.error,
                msg: action.message
            };
        case ADD_CONSIGNEE:
            return {
                ...state,
                loading: false,
                error: action.error,
                msg: action.message
            }
        case UPDATE_CONSIGNEE:
            return {
                ...state,
                loading: false,
                error: action.error,
                msg: action.message
            };
        case REMOVE_CONSIGNEE:
            return {
                ...state,
                loading: false,
                error: action.error,
                msg: action.message
            }
        default:
            return state;
    }
}