import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";

import {useReactToPrint} from "react-to-print";
import {branchUSShippingTableColumns, setActionCallback, UPDATE_RECEIPT, VIEW_RECEIPT} from "../../table_columns";
import BillForm from "../../../shared/BillForm";
import ShippingApprovalModal from "../../../shared/ShippingApprovalModal";
import {getListShipping} from "../../../../api";
import {MyList} from "../../../LoadMoreTable";

const BranchShippingTable = () => {

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [noMoreData, setNoMoreData] = useState(false);

    const {branchVN} = useSelector((state) => state.branch);

    function getShipping(callback) {
        getListShipping({status: 0})
            .then((res) => {
                if (res.data.shipping.length === 0)
                    setNoMoreData(true)
                setData(prevState => ([...prevState, ...res.data.shipping]))
                setPage(page + 1)
                callback()
            })
            .catch((err) => {
                callback()
            });
    }

    setActionCallback(async (type, data) => {
        switch (type) {
            case UPDATE_RECEIPT:
                setPopupData(data)
                setPopupVisible(true)
                break;
            case VIEW_RECEIPT:
                await setPrintData(data)
                handlePrint()
                break;
        }
    });

    //edit shipping approval
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [printData, setPrintData] = useState(null)

    return <div>
        <ShippingApprovalModal
            data={popupData}
            visible={popupVisible}
            onClose={(newData) => {
                setPopupVisible(false);
                if (newData) {
                    setData(data.update(newData, 'tracking_id'))
                }
            }}
        />

        <div style={{display: 'none'}}>
            <BillForm ref={componentRef} data={printData} branchVN={branchVN}/>
        </div>

        <MyList
            rowKey='tracking_id'
            data={data}
            noMoreData={noMoreData}
            columns={branchUSShippingTableColumns}
            fetcher={(callback) => getShipping(callback)}
        />
    </div>;
}

export default BranchShippingTable;
