import {
    doneShipping,
    getListShipping,
    getTransport, getTransportHistory,
    postShipping,
    putSchedule,
    putShipping,
} from "../../api/index";
import {listShipping, loading, readyShipping, transportHistory} from "../actions/shipping";
import {push} from "connected-react-router";
import {message} from "antd";

export const attemptGetListShipping = (region, tracking_ids) => async (dispatch) => {
    dispatch(loading(true))
    await getListShipping(region, tracking_ids)
        .then((res) => {
            dispatch(listShipping(res.data));
        })
        .catch((err) => {
            dispatch(listShipping([]));
        });
};

export const attemptPostShipping = (data) => async (dispatch) => {
    dispatch(loading(true))
    await postShipping(data)
        .then((res) => {
            dispatch(push("/shipping-queue"));
            dispatch(loading(false))
        })
        .catch((err) => {
            dispatch(loading(false))
            dispatch(err)
        });
};

export const attemptApprovalShipping = (data) => async (dispatch) => {
    dispatch(loading(true))
    await putShipping(data)
        .then((res) => {
            dispatch(attemptGetListShipping());
            dispatch(loading(false));
            message.success('Cập nhật thành công.')
        })
        .catch((err) => {
            dispatch(loading(false));
            message.error(err.response.data.message)
        });
};

export const attemptDoneShipping = (data) => async (dispatch) => {
    dispatch(loading(true));
    await doneShipping(data)
        .then((res) => {
            // dispatch(attemptGetListShipping());
            dispatch(loading(false));
            message.success('Cập nhật thành công.')
        })
        .catch((err) => {
            dispatch(loading(false));
            message.error(err.response.data.message)
        });
};

export const attemptGetReadyShipping = (status) => async (dispatch) => {
    dispatch(loading(true))
    await getTransport(status)
        .then((res) => {
            dispatch(readyShipping(res.data));
            return res.data;
        })
        .catch((err) => {

        });
};

export const attemptGetTransportHistory = () => async (dispatch) => {
    await getTransportHistory()
        .then((res) => {
            dispatch(transportHistory(res.data));
            return res.data;
        })
        .catch((err) => {
            dispatch(transportHistory([]));
        });
};

export const attemptAddShippingSchedule = (data) => async (dispatch) => {
    await putSchedule(data)
        .then((res) => {
            message.success('Đặt lịch thành công.')
            dispatch(attemptGetReadyShipping());
        })
        .catch((err) => {
            message.error(err.response.data.message)
        });
};