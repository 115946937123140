import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useReactToPrint} from "react-to-print";
import {
    adminShippingTableColumns1,
    adminShippingTableColumns2,
    EDIT_BOX,
    expandedRowRender,
    setActionCallback,
    UPDATE_RECEIPT,
    VIEW_RECEIPT
} from "../table_columns";
import ShippingApprovalModal from "../../shared/ShippingApprovalModal";
import BillForm from "../../shared/BillForm";
import {getListShipping} from "../../../api";
import {useLocation} from "react-router-dom";
import EditBoxesModal from "./EditBoxesModal";
import {MyList} from "../../LoadMoreTable";


const TrackingShippingTable = () => {

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [noMoreData, setNoMoreData] = useState(false);


    const location = useLocation()
    const {branchVN} = useSelector((state) => state.branch);

    function isQueuePage() {
        return location.pathname.includes("queue")
    }

    function isPending() {
        return location.pathname.includes("pending")
    }

    function getShipping(callback) {
        let filter;
        if (isQueuePage()) {
            filter = {status: 0, page: page}
        } else if (isPending()) {
            filter = {pending: 1, page: page}
        } else {
            filter = {pending: 0, page: page, status: 1}
        }

        getListShipping(filter)
            .then((res) => {
                // if (res.data.shipping.length === 0)
                //     setNoMoreData(true)
                // let data = res.data.shipping.filter(e=> {
                //     let boxCount = 0
                //     e.history.forEach(h=>{
                //         boxCount += h.boxes.length
                //     })
                //     return boxCount < e.meta.box_count_2 || isQueuePage()
                // })
                setData(prevState => ([...prevState, ...res.data.shipping]))
                setPage(page + 1)
                setNoMoreData(true)
                callback()
            })
            .catch((err) => {
                callback()
            });
    }

    setActionCallback(async (type, data) => {
        switch (type) {
            case UPDATE_RECEIPT:
                setPopupData(data)
                setPopupVisible(true)
                break;
            case VIEW_RECEIPT:
                await setPrintData(data)
                handlePrint()
                break;
            case EDIT_BOX:
                setPopupData(data)
                setPopupEditBoxVisible(true)
                break;
        }
    });

    //edit shipping approval
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupEditBoxVisible, setPopupEditBoxVisible] = useState(false);
    const [popupData, setPopupData] = useState(null);

    //print
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [printData, setPrintData] = useState(null)
    //end print


    return <div>
        <ShippingApprovalModal
            data={popupData}
            visible={popupVisible}
            onClose={(value) => {
                if (value) {//ok return
                    // if (value.status === 1) { //remove
                    //     let newData = data.filter(e => e.tracking_id !== value.tracking_id)
                    //     setData(newData)
                    // } else { //update
                    //     let index = data.findIndex(e => e.tracking_id === value.tracking_id);
                    //     data[index] = value
                    //     setData(data)
                    // }
                    let index = data.findIndex(e => e.tracking_id === value.tracking_id);
                    data[index] = value
                    let newData = [...data]
                    setData(newData)
                }
                setPopupVisible(false);
            }}
        />

        {!isQueuePage() ? <EditBoxesModal data={popupData}
                                          visible={popupEditBoxVisible}
                                          onClose={() => {
                                              setPopupEditBoxVisible(false);
                                          }}/> : <></>}


        <div style={{display: 'none'}}>
            <BillForm ref={componentRef} data={printData} branchVN={branchVN}/>
        </div>


        <MyList
            rowKey='tracking_id'
            data={data}
            expandable={{expandedRowRender}}
            noMoreData={noMoreData}
            columns={!isQueuePage() ? adminShippingTableColumns2 : adminShippingTableColumns1}
            fetcher={(callback) => getShipping(callback)}
        />

        
    </div>;
}

export default TrackingShippingTable;
