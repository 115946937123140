import {Button, Col, Modal, Row, Space} from "antd";
import {useDispatch} from "react-redux";
import React, {useState} from "react";
import Text from "antd/es/typography/Text";
import CheckableTag from "antd/es/tag/CheckableTag";
import {Util} from "../../../../utils";
import {attemptDoneShipping} from "../../../../store/thunks/shipping";
import moment from "moment";

const DeliveredConfirmModal = ({visible, shipping, boxesOfDate, onClose}) => {

    const dispatch = useDispatch();

    const [deliveryBoxes, setDeliveryBoxes] = useState(null);

    const onSelectDeliveryBox = (tag, checked) => {
        const nextSelectedTags = checked ? [...deliveryBoxes, tag] : deliveryBoxes.filter(t => t !== tag);
        setDeliveryBoxes(nextSelectedTags)
    }

    const initBoxSelection = () => {
        let delivered_boxes = []
        let transported_boxes = []
        shipping.history.forEach(h => {
            if (h.type === 'transport') {
                transported_boxes.push(...h.boxes)
            } else {
                delivered_boxes.push(...h.boxes)
            }
        })
        if (deliveryBoxes === null) {
            setDeliveryBoxes(Util.diff(delivered_boxes, transported_boxes))
        }
        return Util.toBoxes(shipping.meta.box_num).map(box_num => {
            let isTransportedBox = transported_boxes.indexOf(box_num) > -1;
            let isDeliveredBox = delivered_boxes.indexOf(box_num) > -1;
            let bgColor = isDeliveredBox ? '#52c41a' : isTransportedBox ? '' : 'lightgrey';

            return (
                <CheckableTag
                    style={{
                        userSelect: 'none',
                        border: `1px solid lightgrey`,
                        background: bgColor,
                        width: '30px',
                        height: 'auto',
                        textAlign: 'center',
                        lineHeight: '30px',
                        pointerEvents: !isDeliveredBox && isTransportedBox ? 'auto' : 'none',
                    }}
                    key={box_num}
                    checked={deliveryBoxes?.indexOf(box_num) > -1}
                    onChange={checked => onSelectDeliveryBox(box_num, checked)}>
                    {box_num}
                </CheckableTag>
            )
        })
    }

    return !shipping ? <></> : <Modal
        destroyOnClose={true}
        visible={visible}
        title='Xác nhận đã giao cho:'
        footer={[
            <Button key={1} onClick={() => {
                onClose();
                setDeliveryBoxes(null)
            }}>Đóng</Button>,
            <Button disabled={deliveryBoxes?.length === 0} key={3} type="primary" onClick={() => {
                let delivered_boxes = []
                shipping.history.forEach(h => {
                    if(h.type === 'delivery') delivered_boxes.push(...h.boxes)
                })
                let deliveredBoxesSet = new Set(delivered_boxes ? [...delivered_boxes] : [])
                deliveryBoxes.forEach(e => deliveredBoxesSet.add(e));
                shipping.status = deliveredBoxesSet.size === shipping.meta.box_count_2 ? 3 : 2;


                shipping.done_date = Date.now();
                shipping.history.push({
                    date: moment().format("YYYY-DD-MM"),
                    type: 'delivery',
                    boxes: deliveryBoxes
                })
                
                dispatch(attemptDoneShipping(shipping));
                onClose();
                setDeliveryBoxes(null);
            }}>Đã giao</Button>
        ]}
        onCancel={() => {
            onClose();
            setDeliveryBoxes(null)
        }}>
        <Col>
            <Text strong>{shipping.consignee.name}</Text>
            <br/>
            <Text>{shipping.consignee.phone}</Text>
            <br/>
            <Text>{shipping.consignee.address}</Text>
            <br/>
            <br/>
            <b>Đánh dấu số thùng đã giao:</b>
            <br/>
            {initBoxSelection()}
            <br/>
            <br/>
            <b>Chú thích:</b>
            <br/>
            {
                <Row>
                    <Col sm={10}>
                        <Space>
                            <div style={{width: 22, height: 22, background: 'lightgrey'}}/>
                            Chưa vận chuyển
                        </Space>
                        <br/>
                        <Space>
                            <div style={{width: 22, height: 22, background: '#87d068'}}/>
                            Đã giao
                        </Space>
                    </Col>
                    <Col sm={10}>
                        <Space>
                            <div style={{width: 22, height: 22, background: '#1890ff'}}/>
                            Chọn
                        </Space>
                        <br/>
                        <Space>
                            <div style={{width: 22, height: 22, background: '', border: '1px solid black'}}/>
                            Không chọn
                        </Space>
                    </Col>
                </Row>
            }
        </Col>
    </Modal>
}

export default DeliveredConfirmModal;